import React, { Component } from "react";
import { OperApi } from "app/api/OperApi";
import { connect } from "react-redux";
import { mapStorageDispatchToProps, mapStorageToProps } from "app/redux/mappers";
import { Markup } from "interweave";
import T from "app/i18n/T";
import Utils from "app/Utils";

class Article extends Component<any, any> {
  getData = (lang: any) => {
    OperApi.getArticle(this.props.articleName, lang).then((json: any) => {
        this.setState({
          article: json.data
        });
      }, (error: any) => {
        console.error(error);
      }
    );
  };

  componentWillUpdate(nextProps: any, nextState: any, nextContext: any) {
    this.props.lang !== nextProps.lang && this.getData(nextProps.lang);
  }

  componentDidMount() {
    this.getData(this.props.lang);
  }

  render() {
    if (this.state) {
      return <>
        {this.props.hideTitle || <h2>{this.state.article.title}</h2>}
        {this.props.hideDate || <div className="text-muted">
          {T("Last modified: ")}
          {Utils.getFormattedDate(this.state.article.lastModT)}
        </div>
        }
        <div className="pt-3">
          <Markup content={this.state.article.content} />
        </div>
      </>;
    } else {
      return "";
    }
  }
}

export default connect(mapStorageToProps, mapStorageDispatchToProps)(Article);
