import {store} from "app/redux/redux";
import {translations} from "./i18n"

function T(key: string, forceLang?: string) {

    let lang: any = forceLang ? forceLang : store.getState().lang;

    if (lang !== 'en') {
        return (translations as any)[lang][key] ? (translations as any)[lang][key] : key;
    } else {
        return key;
    }

}

export default T;
