import React, { useEffect, useState } from "react";
import { OperApi } from "app/api/OperApi";
import { connect } from "react-redux";
import {
  mapStorageDispatchToProps,
  mapStorageToProps,
} from "app/redux/mappers";
import { Markup } from "interweave";

interface BuyServiceProps {
  lang: any;
}

const BuyService: React.FC<BuyServiceProps> = (props) => {
  const [article, setArticle] = useState<any | null>(null);

  const getData = (lang: any) => {
    OperApi.getArticle("dashboardPanel", lang).then(
      (json: any) => {
        setArticle(json.data);
      },
      (error: any) => console.error(error),
    );
  };

  useEffect(() => {
    getData(props.lang);
  }, [props.lang]);

  if (!article) {
    return <></>;
  }

  return (
    <div className="background-main h-100 px-4 pb-4 pb-xl-0">
      <div className="pt-3 pb-4">
        <span className="txt-white-big">{article.title}</span>
        <span className="txt-white-small mb-0 txt-news-short-3 mt-2">
          <Markup content={article.content} />
        </span>
      </div>
      <div className="d-flex justify-content-center pb-lg-4">
        <a
          href={article.link}
          className="btn btn-block btn-lg btn-signin-white-thin ebok-color w-100"
        >
          {article.linkText}
        </a>
      </div>
    </div>
  );
};

export default connect(
  mapStorageToProps,
  mapStorageDispatchToProps,
)(BuyService);
