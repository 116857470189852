import React from "react";
import { connect } from "react-redux";
import { mapStorageDispatchToProps, mapStorageToProps } from "app/redux/mappers";
import T from "app/i18n/T";
import Utils from "app/Utils";

interface ServiceCardProps {
  item: any;
  hidden?: boolean;
  address?: any;
}

const ServiceCardNew: React.FC<ServiceCardProps> = (props) => {
  const prepareAddress = (location: any) => {
    if (!location) {
      return <>{T("No address")}</>;
    } else {
      return (
        <>
          {location.street} {location.number}
          <br /> {location.postCode} {location.place}{" "}
        </>
      );
    }
  };

  const getPrice = (service: any) => {
    const currentBillingCycle = service.pricesInBillingPeriods.find(
      (period: any) => period.billingCycle === "CURRENT"
    );
    return currentBillingCycle ? currentBillingCycle.price : null;
  };

  const serviceCardRow = (title: any, value: any) => {
    return (
      <div className="row serviceDetails">
        <div className="col col-6">
          <p className="txt-black-medium mb-0">
            <strong>{title}:</strong>
            <br />
          </p>
        </div>
        <div className="col d-flex justify-content-end justify-content-lg-start">
          <p className="txt-black-small mb-0 txt-l-r">{value}</p>
        </div>
      </div>
    );
  };

  let product = props.item;
  if (props.hidden) {
    return null;
  }

  return (
    <>
      <div className={"col-sm-12 col-md-6 col-lg-4 col-xl-4 pt-3 "}>
        <div className={"service-card shadow-sm d-flex flex-column"}>
          <div className="pt-3 pb-3 pl-3">
            <p className="ebok-color txt-violet-small mb-0">
              {product.service.name}
              <br />
            </p>
          </div>
          <div>
            {serviceCardRow(
              T("Service price"),
              <strong>{Utils.addPrecision(getPrice(product))} zł</strong>
            )}
            {serviceCardRow(
              T("End date"),
              product.validUntil ? Utils.formatDate(product.validUntil) : T("Undefined")
            )}
            {serviceCardRow(
              T("Installation address"),
              prepareAddress(props.address ? props.address : null)
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(mapStorageToProps, mapStorageDispatchToProps)(ServiceCardNew);
