import React, {Component} from 'react';
import 'assets/css/table.css'
import {connect} from "react-redux";
import {mapStorageDispatchToProps, mapStorageToProps} from "app/redux/mappers";

class DtSort extends Component<any, any> {

    dataTableParams = () => {
        return this.props.dataTable[this.props.id + "_params"]
    };

    sort(sort: any) {
        let newParams = this.dataTableParams();
        newParams.sort = sort === undefined || newParams.sort.field !== this.props.column.field ? {
            field: this.props.column.field,
            direction: "DESC"
        } : (sort.direction === "DESC" ? {field: this.props.column.field, direction: "ASC"} : undefined);
        this.props.setDataTableParams(newParams, this.props.id);
        this.props.dt.getData();
    }

    render() {
        if (this.props.column.sort && this.dataTableParams()) {
            let sort = this.dataTableParams().sort;
            return <i
                className={sort === undefined || sort.field !== this.props.column.field ? 'fas fa-sort' : (sort.direction === "DESC" ? 'fas fa-sort-down' : 'fas fa-sort-up')}
                onClick={() => this.sort(sort)}/>;
        }
        return '';
    }
}

export default connect(mapStorageToProps, mapStorageDispatchToProps)(DtSort);
