import React, {Component} from 'react';
import 'assets/css/table.css'
import niepowodzenie from 'assets/img/niepowodzenie_ikona.svg'
import powodzenie from 'assets/img/powodzenie_ikona.svg'
import {NavLink} from "react-router-dom";
import T from "app/i18n/T";


export default class ActionConfirmation extends Component<any, any> {


    render() {
        let icon;

        if (this.props.success) {
            icon = <img alt="" src={powodzenie}/>
        } else {
            icon = <img alt="" src={niepowodzenie}/>
        }

        return <>
            <div className="row d-flex justify-content-center">
                <div className="col col-12">
                    <div className="mt-5 pt-5">
                        <div className="d-flex justify-content-center">{icon}</div>
                        <div>
                            <p className="ebok-color profile-name-card text-center mb-3">{T(this.props.title)}</p>
                        </div>
                        <div>
                            <p className="txt-log-wersal text-center">{T(this.props.message)}</p>
                        </div>
                        {(this.props.loginButton) &&
                        <div className="d-flex justify-content-center my-4">
                            <NavLink exact to="/" className="btn btn-primary btn-block btn-lg btn-max1 w-auto">
                                {T('GO TO LOGIN PAGE')}
                            </NavLink>
                        </div>
                        }
                    </div>
                </div>
            </div>
        </>
    }

}
