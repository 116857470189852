import React, {Component} from "react";
import {connect} from "react-redux";
import {mapStorageDispatchToProps, mapStorageToProps} from "app/redux/mappers";
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from "react-router-dom";
import AppFooter from "app/app-main/AppFooter";
import AppTopbarAnonymous from "./AppTopbarAnonymous";
import {CookiesPopup} from "app/app-components/common/CookiesPopup";
import {routes} from "app/routes/routes-annonymous";

interface AppContentAnonymous extends RouteComponentProps {}

class AppContentAnonymous extends Component<AppContentAnonymous> {


    render() {
        return <>

            <AppTopbarAnonymous/>

            <div>
                <div className="container">

                    <Switch>
                        {routes.map(r => {
                            return <Route {...r} />
                        })}
                        <Route path="/" render={() => (<Redirect to="/"/>)}/>
                    </Switch>

                </div>
            </div>

            <AppFooter/>
            <CookiesPopup/>

        </>
    }

}


export default withRouter(connect(mapStorageToProps, mapStorageDispatchToProps)(AppContentAnonymous));
