import React, { Component } from "react";
import AppContent from "app/app-main/AppContent";
import { connect } from "react-redux";
import { mapStorageDispatchToProps, mapStorageToProps } from "app//redux/mappers";


class AppWrapper extends Component<any, any> {

  render() {
    return (
      <div id="wrapper">
        <AppContent />
      </div>
    );
  }
}

export default connect(mapStorageToProps, mapStorageDispatchToProps)(AppWrapper);
