import moment from "moment";
import Moment from "moment";
import React from "react";
import { store } from "app/redux/redux";
import T from "./i18n/T";
import { ISettlementType } from "@comfortel/ebok-model";


const Utils = {
  formatTime: (param: any) =>
    param != null ? moment(param).format("DD.MM.YYYY HH:mm:ss") : "-",
  formatDate: (param: any) => {
    if (param != null) {
      const momentObj = moment(param);
      const year = momentObj.year();

      if (year > 2300) {
        return T("Indefinitely");
      } else {
        return momentObj.format("DD.MM.YYYY");
      }
    } else {
      return "-";
    }
  },
  checkIfDateIsGreaterThanToday: (date: any) => {
    return moment(date).isAfter(moment(), 'day')
  },
  formatBooleanToYesNo: (param: any) => param ? <i className="fa fa-check" /> : "",
  tryFormatJsonOrEmpty: function(param: any) {
    if (param) {
      try {
        return JSON.parse(param);
      } catch (e) {
        return JSON.parse("{}");
      }
    }
  },
  updateDeepObject: function (form: any, fields: any, value: any) {
    const key = fields.shift();
    if (fields.length > 0) {
      this.updateDeepObject(form[key], fields, value);
    } else {
      form[key] = value;
    }
  },
  formatBadge: (param: any) => (
    <span className="tag-wrapper badge badge-secondary">{param}</span>
  ),
  formatDataExtract: function (data: any, columns: any) {
    return columns.split("|").map((c: any, i: any) => {
      return (
        <div key={i}>
          {c}: {data[c]}
        </div>
      );
    });
  },
  hasPrivilege: function (action: any) {
    return store.getState().user.privileges.includes(action);
  },

  downloadByBlob: function (filename: any, data: any, type: any) {
    let file: Blob;
    if (
      type != null &&
      (type === "application/octet-stream" || type === "application/pdf")
    ) {
      //base64
      let decoded = atob(data);
      let bytes = new Array(decoded.length);
      for (let i = 0; i < decoded.length; i++) bytes[i] = decoded.charCodeAt(i);
      file = new Blob([new Uint8Array(bytes)], { type: type });
    } else file = new Blob([data], { type: type });
    if ((window.navigator as any).msSaveOrOpenBlob)
      // IE10+
      (window.navigator as any).msSaveOrOpenBlob(file, filename);
    else {
      // Others
      let a = document.createElement("a"),
        url = URL.createObjectURL(file);
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      setTimeout(function () {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0);
    }
  },

  addPrecision(value: number) {
    if (!value) {
      return "0.00";
    }
    return value.toFixed(2);
  },

  translateDocumentType(type: ISettlementType): string {
    switch (type) {
      case "INVOICE":
        return T('Invoice');
      case "INVOICE_CORRECTION":
        return T('Invoice correction');
      case "CREDIT_ACCOUNT_NOTE":
        return T('Credit note');
      case "DEBIT_ACCOUNT_NOTE":
        return T('Debit note');
      case "INTEREST_NOTE":
        return T('Interest note');
      default:
       return 'No type'
    }

  },

  getFormattedDate(date: any, format?: any) {
    if (format) {
      return moment(date).format(format);
    } else {
      return moment(date).format("YYYY-MM-DD HH:mm");
    }
  },

  sortByIssueDate(data: any) {
    return data.rows.sort((a: any, b: any) =>
      Moment(b.issueDate, "YYYYMMDD").diff(Moment(a.issueDate, "YYYYMMDD")),
    );
  },

  sortContracts(rows: any) {
    const sortedRows = [...rows];
    sortedRows.sort((a, b) => {
      const dateA = Moment(a.contract.minimalProvisionUntil).format("YYYYMMDD");
      const dateB = Moment(b.contract.minimalProvisionUntil).format("YYYYMMDD");
      if (dateA < dateB) return -1;
      if (dateA > dateB) return 1;

      return a.contract.price - b.contract.price;
    });

    return sortedRows;
  },
};

export default Utils;
