import React, { Component } from "react";
import {
  mapStorageDispatchToProps,
  mapStorageToProps,
} from "app/redux/mappers";
import { connect } from "react-redux";
import { Button, UncontrolledTooltip } from "reactstrap";
import { CustApi } from "app/api/CustApi";
import _ from "lodash";
import T from "../../i18n/T";
import {
  IConsentDto,
  IConsentsResponseDto,
  ICustomerInfoResponseDto,
} from "@comfortel/ebok-model";
import { ApiResponse } from "../../models/ApiModels";

interface CustomerConsentsPropsModel {
  dashboard: {
    customerInfo: ICustomerInfoResponseDto;
  };
  customerData: {
    customerConsents: {
      rows: IConsentDto[];
    };
  };
  setCustomerInfo: (data: ICustomerInfoResponseDto) => void;
  setCustomerConsents: (data: IConsentsResponseDto) => void;
}

interface CustomerConsentsStateModel {
  editing: boolean;
  editConsent: string | null;
}

class CustomerConsents extends Component<
  CustomerConsentsPropsModel,
  CustomerConsentsStateModel
> {
  constructor(props: CustomerConsentsPropsModel) {
    super(props);
    this.state = {
      editing: false,
      editConsent: null,
    };
  }

  printConsentStatusIcon(consentUsage: any) {
    switch (consentUsage.state) {
      case "ACQUIRED":
        return <i className="fal fa-2x fa-check-circle text-success" />;
      case "CANCELLED":
        return <i className="fal fa-2x fa-times-circle text-danger" />;
      default:
        return <i className="fal fa-2x fa-question-circle text-warning" />;
    }
  }

  sendConsent = (consent: any, value: any) => {
    CustApi.sendConsent({
      dictionaryConsent: {
        identifier: consent.dictionaryConsent.identifier,
        name: consent.dictionaryConsent.name,
      },
      consentUsage: {
        usagePresence: "NO_LIMBO",
        state: value,
      },
    }).then(
      (r: ApiResponse<IConsentsResponseDto>) => {
        if (r.meta.status === "SUCCESS") {
          if (r.data) {
            this.props.setCustomerConsents(r.data);
            this.setState({
              editing: false,
              editConsent: null,
            });
            CustApi.customerInfo().then(
              (r: ApiResponse<ICustomerInfoResponseDto>) => {
                if (r.data) {
                  this.props.setCustomerInfo(r.data);
                }
              },
              (error: any) => console.error(error),
            );
          }
        }
      },
      (error: any) => {
        console.error(error);
      },
    );
  };

  consentButtons = (consent: any) => {
    switch (consent.consentUsage.state) {
      case "ACQUIRED":
        return (
          <div className="pt-3">
            <Button
              onClick={() => this.sendConsent(consent, "CANCELLED")}
              color="danger"
              className="mt-2 mt-sm-0 mt-md-0 mt-lg-2 mt-xl-0"
            >
              {T("I do not agree")}
            </Button>
          </div>
        );
      case "CANCELLED":
        return (
          <div className="pt-3">
            <Button
              onClick={() => this.sendConsent(consent, "ACQUIRED")}
              color="success"
              className="mr-2"
            >
              {T("I agree")}
            </Button>
          </div>
        );
      default:
        return (
          <div className="pt-3">
            <Button
              onClick={() => this.sendConsent(consent, "ACQUIRED")}
              color="success"
              className="mr-2"
            >
              {" "}
              {T("I agree")}{" "}
            </Button>
            <Button
              onClick={() => this.sendConsent(consent, "CANCELLED")}
              color="danger"
              className="mt-2 mt-sm-0 mt-md-0 mt-lg-2 mt-xl-0"
            >
              {" "}
              {T("I do not agree")}{" "}
            </Button>
          </div>
        );
    }
  };

  consentCollapse = (consent: IConsentDto) => {
    let consentId = consent.dictionaryConsent.identifier;
    if (this.state.editConsent === consentId && this.state.editing) {
      this.setState({ editing: false, editConsent: "" });
    } else {
      this.setState({ editing: true, editConsent: consentId });
    }
  };

  render() {
    let consents = this.props.customerData.customerConsents;
    let rows: any = consents
      ? _.sortBy(consents.rows, (row) => {
          return row.dictionaryConsent.order;
        })
      : "";

    if (!rows) {
      return <></>;
    }

    return consents ? (
      <div className="bgr-w px-4 pb-4 mt-4 pl-4" style={{ height: "auto" }}>
        <div className="pt-3 pb-3">
          <p className="txt-violet-big ebok-color mb-0">
            {T("Customer consents")}
          </p>
        </div>
        {rows.map((consent: IConsentDto) => {
          return (
            <div
              key={consent.dictionaryConsent.name}
              className="mx-1 bottom-border-grey-2"
            >
              <div className="row pb-4 pt-4">
                <div className="col col-2 col-lg-1">
                  {this.printConsentStatusIcon(consent.consentUsage)}
                </div>
                <div className="col col-9">
                  <p className="txt-black-medium mb-2">
                    <strong>
                      {consent.dictionaryConsent.name}&nbsp;
                      {consent.dictionaryConsent.required && (
                        <>
                          <i
                            className="far fa-exclamation-circle text-muted"
                            id="required-field"
                          />
                          <UncontrolledTooltip
                            placement="right"
                            target="required-field"
                          >
                            {T("This field is required")}
                          </UncontrolledTooltip>
                        </>
                      )}
                    </strong>
                    <br />
                  </p>
                  <p className="txt-black-small mb-0 pr-1">
                    {consent.dictionaryConsent.content}
                    <br />
                  </p>
                  {this.state.editing &&
                  this.state.editConsent ===
                    consent.dictionaryConsent.identifier
                    ? this.consentButtons(consent)
                    : null}
                </div>

                <div className="col pt-4 pt-sm-0 pt-md-4 pt-lg-0 pl-0 col-12 col-md-12 col-xl-2">
                  {consent.dictionaryConsent.required || (
                    <span
                      className=" pointer d-flex align-items-center text-decoration-none justify-content-end"
                      onClick={() => this.consentCollapse(consent)}
                    >
                      <i className="far fa-edit ebok-color" />
                      <p className="txt-violet-small ebok-color mb-0 pl-2">
                        {T("Change")}
                        <br />
                      </p>
                    </span>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    ) : null;
  }
}

export default connect(
  mapStorageToProps,
  mapStorageDispatchToProps,
)(CustomerConsents);
