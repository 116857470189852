import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { SecApi } from "app/api/SecApi";
import T from "app/i18n/T";
import {
  mapStorageDispatchToProps,
  mapStorageToProps,
} from "app/redux/mappers";
import { connect } from "react-redux";
import { FormFeedback, FormGroup, Input } from "reactstrap";
import { getMsg, isValid } from "app/app-components/common/Validation";

class ChangePasswordForm extends Component<any, any> {
  changePassword = (event: any) => {
    event.preventDefault();

    SecApi.resetPasswordConfirm({
      password: event.target.password.value,
      confirmPassword: event.target.confirmPassword.value,
      token: this.props.token,
    }).then(
      (r: any) => {
        let errMessages = r.meta.messages.filter((message: any) => {
          return message.type === "ERROR";
        });
        if (r.meta.status === "SUCCESS") {
          this.props.update({
            showForm: false,
            showAlert: true,
            alertSuccess: true,
            alertTitle: T("Password has been changed"),
            alertMessage: T("You can now log in."),
          });
        } else if (errMessages.length > 0) {
          this.props.update({
            showForm: false,
            showAlert: true,
            alertSuccess: false,
            alertTitle: T("Error occurred"),
            alertMessage: errMessages[0].text,
          });
        }
        this.props.setValidation(r.meta.validation);
      },
      (error: any) => console.log(error),
    );
  };

  render() {
    return (
      <>
        <div className="row d-flex justify-content-center">
          <div className="col col-12 col-sm-8 col-lg-6 col-xl-4">
            <p className="ebok-color profile-name-card">
              {T("Change password")}
            </p>
            <form className="form-signin mt-4" onSubmit={this.changePassword}>
              <FormGroup>
                <Input
                  className="form-control login-box"
                  type="password"
                  id="password"
                  name="password"
                  placeholder={T("New password")}
                  autoFocus={false}
                  invalid={isValid("password")}
                  autoComplete="true"
                />
                <FormFeedback>{getMsg("password")}</FormFeedback>
              </FormGroup>

              <FormGroup>
                <Input
                  className="form-control login-box"
                  type="password"
                  id="confirmPassword"
                  name="confirmPassword"
                  placeholder={T("Repeat password")}
                  invalid={isValid("confirmPassword")}
                />
                <FormFeedback>{getMsg("confirmPassword")}</FormFeedback>
              </FormGroup>

              <button
                className="mt-3 btn btn-primary btn-block btn-lg btn-signin background-main"
                type="submit"
              >
                {T("Change")}
              </button>

              <div className="pt-2 pb-3 justify-content-end d-flex">
                <div className="pt-2 pb-3 justify-content-end d-flex">
                  <NavLink exact to="/">
                    <span className="forgot-password pointer">
                      {T("Back to login form")}
                    </span>
                  </NavLink>
                </div>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
}

export default connect(
  mapStorageToProps,
  mapStorageDispatchToProps,
)(ChangePasswordForm);
