import * as PropTypes from "prop-types";
import React from "react";
import _ from "lodash"

export const SocialInfo = (props: any) => <div id="multiCollapseExample1" className=" top-border">
    <div className="d-flex align-items-start mt-3">
        {!_.isEmpty(props.loginInfo.loginInfo._profile.profilePicURL) &&
        <img alt="" width="50" height="50" className="mr-3"
             src={props.loginInfo.loginInfo._profile ? props.loginInfo.loginInfo._profile.profilePicURL : ""}/>
        }

        <p className="text-man-1">
            {props.loginInfo.loginType === "MOJEID" && <p>Pobrano dane z systemu mojeID</p>}
            <strong>
                {props.loginInfo.loginInfo._profile ? props.loginInfo.loginInfo._profile.name : ""}
            </strong>
            <br/>
            <em>
                {props.loginInfo.loginInfo._profile ? (props.loginInfo.loginType === "MOJEID" ? ("PESEL: *******" + props.loginInfo.loginInfo._profile.id.substr(props.loginInfo.loginInfo._profile.id.length - 4)) : props.loginInfo.loginInfo._profile.email) : ""}
            </em>
        </p>
    </div>
</div>;

SocialInfo.propTypes = {loginInfo: PropTypes.any};
