import React, {Component} from "react";
import AppTopbar from "./AppTopbar";
import {Switch, withRouter, RouteComponentProps} from "react-router-dom";
import {connect} from "react-redux";
import {mapStorageDispatchToProps, mapStorageToProps} from "app/redux/mappers";
import AppSecureRoute from "./AppSecureRoute";
import AppFooter from "./AppFooter";
import {CookiesPopup} from "app/app-components/common/CookiesPopup";
import {routes} from "app/routes/routes-authorized";

interface AppContentProps extends RouteComponentProps {}

class AppContent extends Component<AppContentProps> {
    render() {
        return <div id="content-wrapper" className="d-flex flex-column">

            <div id="content">
                <AppTopbar/>
                <div className="pt-3">
                    <div className="container-fluid">
                        <Switch>
                            {routes.map((r) => {
                                return <AppSecureRoute id={r.path} {...r} />;
                            })}
                        </Switch>
                    </div>
                </div>
            </div>

            <AppFooter/>
            <CookiesPopup/>

        </div>
    }
}

export default withRouter(connect(mapStorageToProps, mapStorageDispatchToProps)(AppContent));
