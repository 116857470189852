import React, { Component } from "react";
import "assets/css/services/custom-scroll.css";
import "assets/css/services/service-card-details.css";
import "assets/css/services/service-card-paddings.css";
import "assets/css/services/service-card.css";
import { CustApi } from "app/api/CustApi";
import { connect } from "react-redux";
import {
  mapStorageDispatchToProps,
  mapStorageToProps,
} from "app/redux/mappers";
import CustomerServices from "app/app-components/services/CustomerServices";

class ViewServices extends Component<any> {
  componentDidMount() {
    CustApi.customerServices().then((json: any) => {
      this.props.setServices(json.data.rows);
    }, null);
  }

  render() {
    return (
      <div className="main">
        <div className="container">
          {this.props.services ? <CustomerServices /> : ""}
          {/*<hr/>
                    <p className="profile-name-card">{T('Additional services')}</p>
                    <BuyServices/>*/}
        </div>
      </div>
    );
  }
}

export default connect(mapStorageToProps, mapStorageDispatchToProps)(ViewServices);
