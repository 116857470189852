import React, {Component} from 'react';
import {connect} from "react-redux";
import {mapStorageDispatchToProps, mapStorageToProps} from "app/redux/mappers";
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";
import {langs} from "./i18n";
import "./LangPicker.css";
import Moment from "moment";

class LangPicker extends Component<any> {

    constructor(props: any) {
        super(props);
        this.lang(localStorage.getItem("lang"))();
    }

    lang(key: any) {
        return () => {
            Moment.updateLocale(key, null);
            this.props.setLang(key)
        }
    }

    render() {
        return <UncontrolledDropdown tag="li"
                                     className="justify-content-start grey-link-ico d-flex align-items-center pb-4 pt-4">

            <DropdownToggle tag="a" caret className="ml-0 d-flex align-items-center pl-lg-3 text-center pointer black" style={{color: "black !important"}}>
                {this.props.globe && <i className="fal fa-2x fa-globe-europe pr-3"/>}
                {langs.filter(l => l.key === this.props.lang)[0].label}
            </DropdownToggle>

            <DropdownMenu>
                {langs.map(l => <DropdownItem key={l.key} onClick={this.lang(l.key)}>{l.label}</DropdownItem>)}
            </DropdownMenu>
        </UncontrolledDropdown>

    }
}

export default connect(mapStorageToProps, mapStorageDispatchToProps)(LangPicker)
