import React, { Component } from "react";
import T from "app/i18n/T";
import { StatusDot } from "app/app-components/common/StatusDot";
import Utils from "app/Utils";
import { CustApi } from "app/api/CustApi";
import DataTable from "app/app-components/common/DataTable/DataTable";
import { connect } from "react-redux";
import {
  mapStorageDispatchToProps,
  mapStorageToProps,
} from "app/redux/mappers";
import { Popover, PopoverBody, PopoverHeader } from "reactstrap";
import QRCode from "qrcode.react";
import { ISettlementType } from "@comfortel/ebok-model";

class InvoicesTable extends Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      qrOpen: null,
      qrText: "",
    };
  }

  invoiceActions(r: any) {
    return (
      <>
            <span className="pointer">
              <i
                className="far fa-file-download fa-lg px-2 ebok-color"
                title={T("Download document")}
                onClick={this.downloadInvoice(r)}
              />
            </span>
      </>
    );
  }

  downloadInvoice = (inv: any) => {
    return () => {
      CustApi.downloadInvoice(inv.uid).then(
        (r: any) => {
          Utils.downloadByBlob(
            r.data.filename,
            r.data.content,
            r.data.mediaType,
          );
        },
        (error: any) => console.error(error),
      );
    };
  };

  orderInvoice = (inv: any) => {
    return () => {
      CustApi.orderInvoice(inv.uid).then(
        () => {
          inv.documentStatus = "PROCESSING";
          this.props.pushToast("s", T("The invoice has been ordered"));
        },
        (error: any) => console.error(error),
      );
    };
  };

  billingActions(r: any) {
    if (r.billingInvoice === "WITH_BILLING") {
      return <>
        <span className="pointer">
          <i className="far fa-file-download fa-lg px-2 ebok-color" title={T("Download billing")}
             onClick={this.downloadInvoiceBilling(r)} />
        </span>
      </>;
    } else {
      return <></>;
    }
  }

  downloadInvoiceBilling = (inv: any) => {
    return () => {
      CustApi.downloadInvoiceBilling(inv.uid).then((r: any) => {
          Utils.downloadByBlob(r.data.filename, r.data.content, r.data.mediaType);
        },
        (error: any) => this.props.pushToast("e", error));
    };
  };

  orderInvoiceBilling = (inv: any) => {
    return () => {
      CustApi.orderInvoiceBilling(inv.uid).then(() => {
          inv.documentStatus = "PROCESSING";
          this.props.pushToast("s", T("The billing has been ordered"));
        },
        (error: any) => this.props.pushToast("e", error));
    };
  };

  toggleQr(id: any, text: any) {
    this.setState({
      qrOpen: this.state.qrOpen === id ? null : id,
      qrText: text,
    });
  }

  closeQr = () => {
    this.setState({
      qrOpen: null,
      qrText: "",
    });
  };

  qrcode(r: any) {
    if (r.remainingDebit === 0 || window.innerWidth < 991 || !r.remainingDebit || r.settlementType == "CREDIT_ACCOUNT_NOTE") {
      return null;
    }

    const id = "qr-" + r.uid;

    const text = [
      this.props.dashboard.operatorInfo.recipientNip,
      "PL",
      r.bankAccountNumber,
      (r.remainingDebit * 100).toFixed(0),
      this.props.dashboard.operatorInfo.recipientName,
      r.number,
      "",
      "",
      "",
    ].join("|");

    return (
      <>
        <i
          id={id}
          className="far fa-qrcode fa-lg px-2 ebok-color pointer"
          onClick={() => this.toggleQr(id, "")}
          title={T("Pay with QR code")}
        />
        <Popover placement="left" isOpen={this.state.qrOpen === id} target={id}>
          <PopoverHeader className="d-flex align-items-center pr-2">
            {T("Pay with QR code")}
            <button
              type="button"
              className="close d-flex align-items-center ml-3"
              onClick={this.closeQr}
            >
              &times;
            </button>
          </PopoverHeader>
          <PopoverBody>
            <div style={{ textAlign: "center" }}>
              <QRCode value={text} size={200} />
            </div>
          </PopoverBody>
        </Popover>
      </>
    );
  }

  render() {
    return (
      <>
        <div className="row mt-4">
          <div className="col">
            <div className="bgr-w">
              <DataTable
                id="ViewInvoiceTable"
                api={CustApi.customerSettlements}
                cols={[
                  {
                    header: T("Document number"),
                    field: "number",
                    tdClassName:
                      "text-center text-nowrap ebok-color txt-violet-small",
                    mobileHeader: true,
                  },
                  {
                    header: T("Document type"),
                    field: "settlementType",
                    formatter: (v: ISettlementType) => <>{Utils.translateDocumentType(v)}</>
                  },
                  {
                    header: T("Document value"),
                    field: "grossAmount",
                    formatter: (v: any) => <>{Utils.addPrecision(v)} zł</>,
                  },
                  {
                    header: T("Issued date"),
                    field: "issueDate",
                    formatter: Utils.formatDate,
                  },
                  {
                    header: T("Due date"),
                    field: "paymentDueDate",
                    formatter: Utils.formatDate,
                  },
                  {
                    header: T("Payment status"),
                    tdClassName: "text-nowrap text-center",
                    formatter: (v: any, r: any) => {
                      if (r.settlementType == "CREDIT_ACCOUNT_NOTE") {
                        return (
                          <>
                            <span className="txt-black-small mb-0">-
                            </span>
                          </>
                        );
                      } else {
                        return (
                          <>
                          <span className="txt-black-small mb-0">
                            <StatusDot status={r.isPaid} />
                            &nbsp;{r.isPaid ? T("Paid") : T("Not paid")}
                          </span>
                          </>
                        );
                      }
                    },
                  },
                  {
                    header: T("Actions"),
                    tdClassName: "text-right",
                    formatter: (v: any, r: any) => (
                      <>
                        {this.qrcode.bind(this)(r)}
                        {this.billingActions.bind(this)(r)}
                        {this.invoiceActions.bind(this)(r)}
                      </>
                    ),
                    mobileHeader: false,
                  },
                ]}
                pageable={false}
                sortByType="INVOICE"
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default connect(
  mapStorageToProps,
  mapStorageDispatchToProps,
)(InvoicesTable);
