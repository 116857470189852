import React, { useState } from "react";
import T from "app/i18n/T";
import { connect } from "react-redux";
import {
  mapStorageDispatchToProps,
  mapStorageToProps,
} from "app/redux/mappers";
import {
  Button,
  Form,
  FormFeedback,
  Input,
  InputGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import Logger from "app/Logger";
import { CustApi } from "app/api/CustApi";
import { getMsg, isValid } from "app/app-components/common/Validation";

interface ContactProps {
  dashboard: {
    operatorInfo: {
      chatPath: string;
      phoneNumber: string;
      contactMail: string;
    };
  };
  pushToast: (type: string, message: string) => void;
  setValidation: (data: any) => void;
}

const Contact: React.FC<ContactProps> = (props) => {
  const [contactForm, setContactForm] = useState(false);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");

  const toggle = () => {
    setContactForm(!contactForm);
  };

  const editField = (key: string, value: string) => {
    switch (key) {
      case "title":
        setTitle(value);
        break;
      case "content":
        setContent(value);
        break;
      default:
        break;
    }
  };

  const sendContactRequest = () => {
    Logger.debug("Wysyłam req");
    Logger.debug({ title, content });
    CustApi.sendReport({ content, title }).then(validateResponse, (error: any) =>
      console.error(error)
    );
  };

  const validateResponse = (r: any) => {
    if (r.meta.status === "SUCCESS") {
      setContactForm(false);
      props.pushToast("s", T("Report was sent successfully"));
    } else {
      props.pushToast("e", T("Error occurred while sending the report"));
    }

    props.setValidation(r.meta.validation);
  };

  const contactModal = () => {
    return (
      <div>
        <Modal isOpen={contactForm} toggle={toggle} style={{ top: 60 }}>
          <ModalHeader toggle={toggle}>{T("Contact form")}</ModalHeader>
          <Form onSubmit={(e) => console.log(e)}>
            <ModalBody>
              <InputGroup className="mb-3">
                <Input
                  type="text"
                  placeholder={T("Report title")}
                  onChange={(e) => editField("title", e.target.value)}
                  invalid={isValid("title")}
                />
                <FormFeedback>{getMsg("title")}</FormFeedback>
              </InputGroup>
              <InputGroup>
                <Input
                  type="textarea"
                  rows={10}
                  placeholder={T("Report content")}
                  onChange={(e) => editField("content", e.target.value)}
                  invalid={isValid("content")}
                />
                <FormFeedback>{getMsg("content")}</FormFeedback>
              </InputGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={sendContactRequest}>
                {T("Send report")}
              </Button>{" "}
              <Button color="secondary" onClick={toggle}>
                {T("Cancel")}
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      </div>
    );
  };

  if (!props.dashboard.operatorInfo) {
    return null;
  }

  return (
    <div className="bgr-w h-100 px-4 pb-xl-0">
      <div className="pt-3 pb-2">
        <p className="ebok-color txt-violet-big mb-3">
          {T("Report a problem")}
        </p>
      </div>

      <div className="dane-mb">
        <span onClick={toggle} style={{ cursor: "pointer" }}>
          <div className="d-inline-flex dane-mb-midi">
            <div>
              <i className="fas fa-lg fa-comment-alt ebok-color" />
            </div>
            <div>
              <span className="txt-black-small ml-3">
                {T("Contact form")}
                <br />
              </span>
            </div>
          </div>
        </span>
      </div>

      <div className="d-inline-flex dane-mb">
        <div>
          <i className="fas fa-lg fa-phone-alt ebok-color" />
        </div>
        <div>
          <p className="txt-black-small ml-3">
            {T("Phone")}: {props.dashboard.operatorInfo.phoneNumber}
          </p>
        </div>
      </div>

      <a href={"mailto:" + props.dashboard.operatorInfo.contactMail}>
        <div className="dane-mb">
          <div className="d-inline-flex pb-2 pb-xl-0">
            <div>
              <i className="fas fa-lg fa-envelope-open-text ebok-color" />
            </div>
            <div className="mt-1">
              <p className="txt-black-small ml-3">
                e-mail: <span>{props.dashboard.operatorInfo.contactMail}</span>
              </p>
            </div>
          </div>
        </div>
      </a>

      {contactModal()}
    </div>
  );
};

export default connect(mapStorageToProps, mapStorageDispatchToProps)(Contact);
