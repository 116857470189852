import React, {Component} from "react";
import "assets/css/message-table.css"

export class MessageIcon extends Component<any> {
    icon = () => {
        switch (this.props.messageType) {
            case 'GLOBAL':
                return <i className="fas fa-globe-europe"/>;
            case "INVOICE":
                return <i className="fa fa-file-invoice-dollar"/>;
            case "INDIVIDUAL":
                return <i className="fas fa-envelope"/>;
            default:
                break;
        }
    };

    render() {

        return <span className="message-table-icon ebok-color">
            {this.icon()}
        </span>;
    }
}
