import React, { Component } from "react";
import "assets/css/dots.css";
import "assets/css/ebok-color.css";
import { connect } from "react-redux";
import { mapStorageDispatchToProps, mapStorageToProps } from "app/redux/mappers";
import { CustApi } from "app/api/CustApi";
import LastInvoice from "app/app-components/payments/LastInvoice";
import InvoicesTable from "app/app-components/payments/InvoicesTable";
import PaymentsChart from "app/app-components/payments/PaymentsChart";
import {
  ICustomerInfoResponseDto,
  IFinancialStatusDto,
  ILastSettlementResponseDto,
  ISettlementDto,
} from "@comfortel/ebok-model";
import { ApiResponse } from "../../models/ApiModels";

interface ViewPaymentsPropsModel {
  dashboard: {
    customerInfo: ICustomerInfoResponseDto;
  };
  setCustomerInfo: (data: ICustomerInfoResponseDto) => void;
  setCustomerPayment: (data: ILastSettlementResponseDto) => void;
}

interface ViewPaymentsStateModel {
  lastInvoicePresent: boolean;
  lastInvoice: {
    settlement: ISettlementDto;
    financialStatus: IFinancialStatusDto;
  } | null;
  documents: ISettlementDto[];
}

class ViewPayments extends Component<
  ViewPaymentsPropsModel,
  ViewPaymentsStateModel
> {
  constructor(props: ViewPaymentsPropsModel) {
    super(props);
    this.state = {
      lastInvoicePresent: false,
      lastInvoice: null,
      documents: [],
    };
  }

  componentDidMount() {
    if (!this.props.dashboard.customerInfo) {
      CustApi.customerInfo().then(
        (r: ApiResponse<ICustomerInfoResponseDto>) => {
          if (r.data) {
            this.props.setCustomerInfo(r.data);
          }
        },
        (error: any) => console.error(error),
      );
    }

    CustApi.customerLastSettlement().then(
      (r: ApiResponse<ILastSettlementResponseDto>) => {
        if (r.data) {
          this.setState({
            lastInvoice: r.data,
            lastInvoicePresent: true,
          });
        }
      },
      (error: any) => console.error(error),
    );
  }

  render() {
    return (
      <div className="container bgr-grey py-4">
        <div className="row">
          <div className="col col-12 col-lg-6 col-xl-4 px-3">
            <div className="bgr-w h-100 px-4 pb-4 m-0 p-0">
              <LastInvoice
                invoice={this.state?.lastInvoice?.settlement ?? null}
                financialStatus={
                  this.state?.lastInvoice?.financialStatus ?? null
                }
              />
            </div>
          </div>
          <div
            className="col col-12 col-lg-6 col-xl-8 px-3 pt-3 pt-lg-0 pt-xl-0 mt-xl-0"
            style={{ minHeight: "500px" }}
          >
            <div className="bgr-w h-100 px-4 pb-4 m-0 p-0">
              <div className="pt-3 pb-3">
                <PaymentsChart />
              </div>
            </div>
          </div>
        </div>
        <InvoicesTable data={this.state?.documents} />
      </div>
    );
  }
}

export default connect(
  mapStorageToProps,
  mapStorageDispatchToProps,
)(ViewPayments);
