import { Api, BASIC_PORT } from "./Api";
import { DEV } from "../models/ApiModels";
import { Dev$CLEAN, Dev$CLEAR_CURRENT } from "@comfortel/ebok-model/constants";

export class DevApi extends Api {

	static apiUrl = (window.location.port === BASIC_PORT && (window as any).var.DEVAPI_URL) || '/dev-api';

    static clearCacheForCurrentUser() {
        return DevApi.get(DEV + Dev$CLEAR_CURRENT)
    }

    static clearAllCache() {
        return DevApi.get(DEV + Dev$CLEAN)
    }

}
