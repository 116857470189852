import { combineReducers, legacy_createStore } from "redux";

import moment from "moment";
import Logger from "app/Logger";
import { ICustomerInfoResponseDto } from "@comfortel/ebok-model";


/**
 * Lang
 */
export const setLang = (lang: string) => ({
  type: "SET_LANG",
  lang
});

export const lang = (state = "pl", action: any) => {
  if (action.type === "SET_LANG") {
    localStorage.setItem("lang", action.lang);
    return action.lang;
  } else {
    return state;
  }
};


/**
 * User
 */
export const setUser = (user: any) => ({
  type: "SET_USER",
  user
});

export const clearUser = () => ({
  type: "CLEAR_USER"
});

export const user = (state = {}, action: any) => {
  switch (action.type) {
    case "SET_USER":
      return action.user;
    case "CLEAR_USER":
      return {};
    default:
      return state;
  }
};


/**
 * Validation
 */
export const setValidation = (validation: any) => ({
  type: "SET_VALIDATION",
  validation
});


export const clearValidation = () => ({
  type: "CLEAR_VALIDATION"
});

export const validation = (state = [], action: any) => {
  switch (action.type) {
    case "SET_VALIDATION":
      return action.validation;
    case "CLEAR_VALIDATION":
      return [];
    default:
      return state;
  }
};

/**
 * Customer Data Screen
 */
export const setCustomerData = (customerInfo: any) => ({
  type: "SET_CUSTOMER_BASIC_INFO",
  customerInfo
});


export const setCustomerConsents = (customerConsents: any) => ({
  type: "SET_CUSTOMER_CONSENTS",
  customerConsents
});

export const customerData = (state = {}, action: any) => {
  switch (action.type) {
    case "SET_CUSTOMER_BASIC_INFO":
      return {
        ...state,
        customerBasicInfo: action.customerInfo
      };
    case "SET_CUSTOMER_CONSENTS":
      return {
        ...state,
        customerConsents: action.customerConsents
      };
    default:
      return state;
  }
};


/**
 * Dashboard
 */
export const setCustomerInfo = (customerInfo: ICustomerInfoResponseDto) => ({
  type: "SET_CUSTOMER_INFO",
  customerInfo
});

export const setOperatorInfo = (operatorInfo: any) => ({
  type: "SET_OPERATOR_INFO",
  operatorInfo
});

/**
 * Config
 */
export const setOperatorConfig = (operatorConfig: any) => ({
  type: "SET_OPERATOR_CONFIG",
  operatorConfig
});

export const operatorConfig = (state = {}, action: any) => {
  if (action.type === "SET_OPERATOR_CONFIG") {
    return action.operatorConfig;
  } else {
    return state;
  }
};

/**
 * Theme
 */
const initialState = {
  theme: {},
  contrast: 0,
  font: 0
};

export const setTheme = (theme: any) => ({
  type: "SET_THEME",
  theme
});
export const setContrast = (contrast: any) => ({
  type: "SET_CONTRAST",
  contrast
});
export const setFont = (font: any) => ({
  type: "SET_FONT",
  font
});

export const theme = (state = initialState, action: any) => {
  switch (action.type) {
    case "SET_THEME":
      return {
        ...state,
        theme: action.theme
      };
    case "SET_CONTRAST":
      return {
        ...state,
        contrast: action.contrast
      };
    case "SET_FONT":
      return {
        ...state,
        font: action.font
      };
    default:
      return state;
  }
};


export const setCustomerPayment = (customerPayment: any) => ({
  type: "SET_CUSTOMER_PAYMENT",
  customerPayment
});

export const setCustomerServices = (customerServices: any) => ({
  type: "SET_CUSTOMER_SERVICES",
  customerServices
});

export const dashboard = (state = {}, action: any) => {
  switch (action.type) {
    case "SET_CUSTOMER_INFO":
      return {
        ...state,
        customerInfo: action.customerInfo
      };
    case "SET_OPERATOR_INFO":
      return {
        ...state,
        operatorInfo: action.operatorInfo
      };
    case "SET_CUSTOMER_PAYMENT":
      return {
        ...state,
        customerPayment: action.customerPayment
      };
    case "SET_CUSTOMER_SERVICES":
      return {
        ...state,
        customerServices: action.customerServices
      };
    default:
      return state;
  }
};

/**
 * Services
 */

export const setServices = (services: any) => ({
  type: "SET_SERVICES",
  services
});

export const services = (state = {}, action: any) => {
  if (action.type === "SET_SERVICES") {
    return {
      ...state,
      services: action.services
    };
  } else {
    return state;
  }
};

/**
 * Application unavailable
 */


export const setUnavailable = () => ({
  type: "SET_APP_UNAVAILABLE"
});

export const setAvailable = () => ({
  type: "SET_APP_AVAILABLE"
});

export const unavailable = (state = false, action: any) => {
  switch (action.type) {
    case "SET_APP_UNAVAILABLE":
      return true;
    case "SET_APP_AVAILABLE":
      return false;
    default:
      return state;
  }
};


/**
 * Toasts
 */
export const pushToast = (t:any, msg: any) => {

  setTimeout(() => {
    store.dispatch(dismissToast(t));
  }, 10000);

  return ({
    type: "PUSH_TOAST",
    t,
    msg
  });
};

export const dismissToast = (toast: any) => ({
  type: "DISMISS_TOAST",
  state: store.getState().toast,
  t: toast
});

export const toast = (state: any = [], action: any) => {
  switch (action.type) {
    case "PUSH_TOAST":
      state.unshift({ timestamp: moment.now(), type: action.t, msg: action.msg });
      return state.splice(0, 5);
    case "DISMISS_TOAST":
      let index = state.indexOf(action.t);
      state.splice(index, 1);
      return Object.assign([], state);
    default:
      return state;
  }
};


/**
 * Loading
 */
export const ajaxStart = () => ({
  type: "AJAX_START"
});

export const ajaxStop = () => ({
  type: "AJAX_STOP"
});

export const loading = (state: any = 0, action: any) => {
  switch (action.type) {
    case "AJAX_START":
      Logger.debug("start ajax");
      return state + 1;
    case "AJAX_STOP":
      Logger.debug("stop ajax");
      return state - 1;
    default:
      return state;
  }
};

/**
 * Msg Counter
 */
export const setMsgCounter = (value: any): any => ({
  type: "SET_MSG_COUNTER",
  value
});

export const msgCounter = (state: any = 0, action: any) => {
  if (action.type === "SET_MSG_COUNTER") {
    Logger.debug(action.value);
    return action.value;
  } else {
    return state;
  }
};


/**
 * DataTables
 */
export const setDataTable = (dataTable: any, name: any) => ({
  type: "SET_DATA_TABLE",
  dataTable,
  name
});

export const setDataTableParams = (params: any, name: any) => ({
  type: "SET_DATA_TABLE_PARAMS",
  params,
  name
});

export const dataTable = (state = {}, action: any) => {
  switch (action.type) {
    case "SET_DATA_TABLE":
      Logger.debug("SET_DATA_TABLE", action, state);
      return {
        ...state,
        [action.name]: {
          rows: action.dataTable.rows,
          total: action.dataTable.total
        }
      };
    case "SET_DATA_TABLE_PARAMS":
      Logger.debug("SET_DATA_TABLE_PARAMS", action, state);
      return {
        ...state,
        [action.name + "_params"]: action.params
      };
    default:
      return state;
  }
};


export const appReducers = combineReducers({
  user,
  lang,
  toast,
  loading,
  dashboard,
  services,
  validation,
  operatorConfig,
  theme,
  msgCounter,
  dataTable,
  customerData,
  unavailable
});

export const appClearReducer = combineReducers({
  user: () => ({}),
  lang,
  toast,
  loading,
  dashboard: () => ({}),
  services: () => ({}),
  validation,
  operatorConfig,
  theme,
  msgCounter,
  dataTable: () => ({}),
  customerData: () => ({}),
  unavailable
});

const reducers = (state: any, action: any) => {
  if (action.type === "CLEAR_USER") {
    return appClearReducer(state, action);
  }
  return appReducers(state, action);
};

export function configureStore(initialState = {}) {
  return legacy_createStore(reducers, initialState);
}

export const store = configureStore();
