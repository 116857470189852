import React, { useEffect, useState } from "react";
import {
  mapStorageDispatchToProps,
  mapStorageToProps,
} from "app/redux/mappers";
import { connect } from "react-redux";
import { contactTypesLang } from "app/i18n/ContactTypesLang";
import Input from "reactstrap/lib/Input";
import {
  Button,
  FormFeedback,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { Form } from "react-bootstrap";
import { CustApi } from "app/api/CustApi";
import { getMsg, isValid } from "../common/Validation";
import T from "../../i18n/T";
import { withRouter } from "react-router-dom";
import Logger from "../../Logger";
import { SecApi } from "../../api/SecApi";
import {
  IContactDataDto,
  ICustomerInfoResponseDto,
} from "@comfortel/ebok-model";

interface CustomerContactProps {
  dashboard: {
    customerInfo: ICustomerInfoResponseDto;
  };
  setCustomerInfo: (customerInfo: any) => void;
  setValidation: (validation: any) => void;
  clearUser: () => void;
  history: any;
}

const CustomerContact: React.FC<CustomerContactProps> = ({
  dashboard,
  setCustomerInfo,
  setValidation,
  clearUser,
  history,
}) => {
  const [editing, setEditing] = useState(false);
  const [form, setForm] = useState({
    phone: "",
    email: "",
  });
  const [changePasswordModal, setChangePasswordModal] = useState(false);
  let customerInfo = dashboard.customerInfo;
  let contactMethodLang = contactTypesLang();

  useEffect(() => {
    if (customerInfo) {
      let phone = customerInfo.contactData.find(
        (method: any) => method.type === "PHONE",
      );
      let mail = customerInfo.contactData.find(
        (method: any) => method.type === "EMAIL",
      );
      setForm({
        phone: phone && phone.value ? phone.value : "",
        email: mail && mail.value ? mail.value : "",
      });
    }
  }, [customerInfo]);


  if (!customerInfo) {
    return <></>;
  }

  const onFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    setForm((prevState) => ({
      ...prevState,
      [e.target.name.toLowerCase()]: e.target.value,
    }));
  };

  const sendForm = (e: React.FormEvent) => {
    e.preventDefault();
    CustApi.sendCustomerInfo(form).then(
      (r: any) => {
        validateResponse(r);
      },
      (error: any) => Logger.error(error),
    );
  };

  const validateResponse = (r: any) => {
    if (r.meta.status === "SUCCESS") {
      setCustomerInfo(r.data);
      setEditing(false);
    }

    setValidation(r.meta.validation);
  };

  const redirectToChangePassword = () => {
    setChangePasswordModal(false);
    SecApi.logout();
    clearUser();
    history.push("/forgotpassword");
  };

  const corespondentAddress = () => {
    return (
      <div className="col pl-3 pl-sm-0">
        <p className="txt-black-small mb-0 pr-1 pl-3">
          {customerInfo.personalData.forename}{" "}
          {customerInfo.personalData.surname}{" "}
          {customerInfo.type === "BUSINESS" &&
            customerInfo.correspondenceData.personalData.companyName}
          <br />
          {customerInfo.correspondenceData.address.formattedAddress}
          <br />
        </p>
      </div>
    );
  };

  const contactMethods = () => {
    const sortedContactData = customerInfo.contactData
      .slice()
      .sort((a: IContactDataDto, b: IContactDataDto) => {
        return a.type.localeCompare(b.type);
      });
    return (
      <>
        {}
        {editing ? (
          <>
            {sortedContactData.map(
              (contactMethod: IContactDataDto, index: number) => {
                return (
                  <div key={index} className="row pb-2">
                    <div className="col col-12 pl-3 pl-sm-0">
                      <div className="row pl-4 pr-2">
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              {(contactMethodLang as any)[contactMethod.type]}{contactMethod.type === 'EMAIL' ? "*" : null}
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type="text"
                            name={contactMethod.type}
                            onChange={onFormChange}
                            defaultValue={contactMethod.value}
                            invalid={isValid(contactMethod.type.toLowerCase())}
                          />
                          <FormFeedback className="changeDataFormInvalid">
                            {getMsg(contactMethod.type.toLowerCase())}
                          </FormFeedback>
                        </InputGroup>
                      </div>
                    </div>
                  </div>
                );
              },
            )}
          </>
        ) : (
          sortedContactData.map((contactMethod: any, index: number) => {
            return (
              <div key={index} className="row pb-3">
                <div className="col col-sm-6 col-xl-4 mb-2 mb-sm-0">
                  <p className="txt-black-medium mb-0">
                    <strong>
                      {(contactMethodLang as any)[contactMethod.type]}:
                    </strong>
                    <br />
                  </p>
                </div>
                <div className="col pl-3 pl-sm-0">
                  <p className="txt-black-small mb-0 pr-1 pl-3">
                    {contactMethod.value}
                    <br />
                  </p>
                </div>
              </div>
            );
          })
        )}
      </>
    );
  };

  const passwordField = () => {
    return (
      <div className="row pb-3">
        <div className="col col-sm-6 col-xl-4 mb-2 mb-sm-0">
          <p className="txt-black-medium mb-0">
            <strong>{T("Password")}:</strong>
            <br />
          </p>
        </div>
        <div className="col pl-3 pl-sm-0">
          <span
            className="d-flex pointer align-items-center text-decoration-none justify-content-start"
            onClick={() => {
              setChangePasswordModal(true);
            }}
          >
            <i className="far fa-key ebok-color" />
            <p className="txt-violet-small ebok-color mb-0 pl-2">
              {T("Change password")} <br />
            </p>
          </span>
        </div>
        <Modal
          isOpen={changePasswordModal}
          toggle={() => setChangePasswordModal(false)}
          modalTransition={{ timeout: 500 }}
          centered
        >
          <ModalHeader toggle={() => setChangePasswordModal(false)}>
            <p className="txt-violet-big ebok-color mb-0">
              {T("Change password")}
            </p>
          </ModalHeader>
          <ModalBody>
            <p className="txt-black-small mb-0">
              {T("You will be taken to the password reset form")}
            </p>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => redirectToChangePassword()}>
              {T("Confirm")}
            </Button>
            <Button
              color="secondary"
              onClick={() => setChangePasswordModal(false)}
            >
              {T("Cancel")}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  };

  return (
    <div className="bgr-w px-4 pb-4 mt-4 mt-md-0" style={{ height: "auto" }}>
      <div className="pt-3 pb-3">
        <p className="txt-violet-big ebok-color mb-0">{T("Contact data")}</p>
      </div>
      <Form onSubmit={sendForm}>
        <div className="row pb-3">
          <div className="col col-12 col-sm-6 col-xl-4 mb-2 mb-sm-0">
            <p className="txt-black-medium mb-0">
              <strong>{T("Correspondence Address")}:</strong>
              <br />
            </p>
          </div>
          <div>{corespondentAddress()}</div>
        </div>
        <p className="txt-black-medium mb-0 pt-3 pb-2">
          <strong>{T("Contact methods")}:</strong>
          <br />
        </p>
        {contactMethods()}
        {passwordField()}
        <div className="row">
          <div className="col col-12">
            {editing ? (
              <span className="d-flex pt-3 pointer align-items-center text-decoration-none justify-content-end">
                <Button color="success" className="mr-2">
                  {T("Save")}
                </Button>
                <Button
                  color="danger"
                  onClick={() => {
                    setEditing(false);
                  }}
                >
                  {T("Cancel")}
                </Button>
              </span>
            ) : (
              <span
                className="d-flex pointer align-items-center text-decoration-none justify-content-end"
                onClick={() => {
                  setEditing(true);
                }}
              >
                <i className="far fa-edit ebok-color" />
                <p className="txt-violet-small ebok-color mb-0 pl-2">
                  {T("Edit")} <br />
                </p>
              </span>
            )}
          </div>
        </div>
      </Form>
    </div>
  );
};

export default withRouter(
  connect(mapStorageToProps, mapStorageDispatchToProps)(CustomerContact),
);
