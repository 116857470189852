import React from "react";
import {
  mapStorageDispatchToProps,
  mapStorageToProps,
} from "app/redux/mappers";
import { connect } from "react-redux";
import ServiceCardNew from "./ServiceCardNew";
import T from "../../i18n/T";
import Utils from "../../Utils";

const CustomerServices = (props: any) => {
  const noServices = () => {
    return (
      <div className="d-flex justify-content-center">
        <h3 className="ebok-color">{T("No active services")}</h3>
      </div>
    );
  };

  const renderServiceCards = () => {
    const { dashboard } = props;
    const services = Utils.sortContracts(props.services);

    if (!services || !dashboard || !services.length) {
      return noServices();
    }

    return (
      <div>
        {services.map((contract: any, contractIndex: number) => {
          if (contract) {
            const sortedProducts = contract.contract.products.sort(
              (a: any, b: any) => a.name.localeCompare(b.name),
            );
            return (
              <div className="container bgr-grey mb-4 pt-3" key={contractIndex}>
                <h3 className="ebok-color txt-violet-vbig ml-3 mb-0">
                  {T("Agreement")}: {contract.contract.number}{" "}
                </h3>
                <div className="row card-paddings">
                  {sortedProducts.flatMap(
                    (product: any, productIndex: number) => {
                      if (product) {
                        return product.subscriptions.map(
                          (serviceItem: any, serviceItemIndex: number) => (
                            <ServiceCardNew
                              key={`${contractIndex}-${productIndex}-${serviceItemIndex}`} // Unikalny klucz na podstawie indeksów
                              address={
                                contract.serviceLocation?.address || null
                              }
                              item={serviceItem}
                            />
                          ),
                        );
                      } else {
                        return [];
                      }
                    },
                  )}
                </div>
              </div>
            );
          } else {
            return [];
          }
        })}
      </div>
    );
  };

  if (props.services && props.dashboard) {
    return renderServiceCards();
  }
  return noServices();
};

export default connect(
  mapStorageToProps,
  mapStorageDispatchToProps,
)(CustomerServices);
