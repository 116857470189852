import React, { Component } from "react";
import "assets/css/table.css";
import { connect } from "react-redux";
import { mapStorageDispatchToProps, mapStorageToProps } from "app/redux/mappers";
import T from "app/i18n/T";
import DtPagination from "./DtPagination";
import DtSort from "./DtSort";
import DtFilters from "./DtFilters";
import { Button, Card, CardBody, CardHeader, Collapse, Row } from "reactstrap";
import Col from "react-bootstrap/Col";
import Utils from "../../../Utils";

class DataTable extends Component<any, any> {

  static defaultProps = {
    labels: {
      pageSize: "Rekordów",
      page: "Strona",
      of: "z",
      showFilters: "Pokaż filtry",
      hideFilters: "Ukryj filtry",
      clear: "Wyczyść",
      search: "Wyszukaj"
    },
    defaultPage: 1,
    defaultPageSize: 20,
    defaultFilters: {},
    defaultSort: undefined
  };

  constructor(props: any) {
    super(props);
    this.props.setDataTableParams(this.copyDefaultCriteria(), this.props.id);
    this.getData = this.getData.bind(this);
    this.getData();

    this.state = {
      collapse: null
    };

  }

  copyDefaultCriteria() {
    return JSON.parse(
      JSON.stringify({
        page: this.props.defaultPage,
        pageSize: this.props.defaultPageSize,
        filters: this.props.defaultFilters,
        sort: this.props.defaultSort
      })
    );
  }

  dataTable = () => {
    return this.props.dataTable[this.props.id];
  };

  dataTableParams = () => {
    return this.props.dataTable[this.props.id + "_params"];
  };

  getData() {
    this.props.api(this.dataTableParams() ? this.dataTableParams() : this.copyDefaultCriteria()).then((r: any) => {
      if (this.props.sortByType) {
        let sortedData = r.data;
        if (this.props.sortByType === "INVOICE") {
          sortedData = Utils.sortByIssueDate(r.data);
        }
        if (this.props.sortByType === "SERVICES") {
          sortedData = Utils.sortContracts(r.data.rows);
        }
        this.props.setDataTable({ ...r.data, rows: [...sortedData] }, this.props.id);
      } else {
        this.props.setDataTable(r.data, this.props.id);
      }
    });
  }

  printHeaderWeb() {
    return <tr>
      {this.props.cols.map((c: any, i: any) => {
        return <th key={i}
                   className={(c.thClassName ? c.thClassName : "text-center")}>{c.header} <DtSort
          id={this.props.id} column={c} dt={this} /></th>;
      })}
    </tr>;
  }

  printBodyWeb() {
    return (this.dataTable().total > 0) ? this.dataTable().rows.map((r: any, i: any) => {
      return <tr key={i}>
        {this.props.cols.map((c: any, ci: any) => {
          let value = c.field ? c.field.split(".").reduce((res: any, curr: any) => res[curr], r) : "";
          return <td className={c.tdClassName ? c.tdClassName : "text-center txt-black-small"}
                     key={ci}>{c.formatter ? c.formatter(value, r, c, this) : value}</td>;
        })}
      </tr>;
    }) : <tr>
      <td className="text-center" colSpan={this.props.cols.length}>{T("No Data")}</td>
    </tr>;
  }

  printFilters() {
    return (this.props.filters && this.props.filters.length) > 0 ?
      <DtFilters {...this.props} getData={this.getData} /> : "";
  }

  printPagination() {
    if (this.props.pageable === false) {
      return "";
    }
    return this.dataTable().total > 0 ? <DtPagination {...this.props} getData={this.getData} /> : "";
  }

  render() {
    return (this.dataTableParams() && this.dataTable()) ? <div>
      {this.printFilters()}
      {window.innerWidth >= 991 ? this.renderForWeb() : this.renderForMobile()}
      {this.printPagination()}
    </div> : "";
  }

  toggle(e: any) {
    let event = e.target.dataset.event;
    this.setState({ collapse: this.state.collapse === Number(event) ? -1 : Number(event) });
  }

  renderForMobile() {
    return <div className="container" style={{ paddingTop: 10, paddingBottom: 10 }}>

      {this.dataTable().rows.map((row: any, index: number) => {
        return (
          <Card style={{ marginBottom: "2px" }} key={index}>
            <CardHeader>
              <Row className="align-items-center">
                <Col xs={1} sm={1} md={1}>
                  <Button color="default" onClick={this.toggle.bind(this)} data-event={index}>
                    <i
                      className={"fas " + (this.state.collapse === index ? "fa-chevron-circle-down" : "fa-chevron-circle-right")}
                      onClick={this.toggle.bind(this)} data-event={index} />
                  </Button>
                </Col>
                {this.props.cols
                  .filter((c: any) => c.mobileHeader)
                  .map((c: any, i: any) => {
                    let value = c.field ? c.field.split(".").reduce((res: any, curr: any) => res[curr], row) : "";
                    return <Col key={i} className="ml-1">
                      <strong>{c.formatter ? c.formatter(value, row) : value}</strong>
                    </Col>;
                  })
                }
              </Row>

            </CardHeader>
            <Collapse isOpen={this.state.collapse === index}>
              <CardBody>
                {this.props.cols
                  .filter((c: any) => !c.mobileHeader)
                  .map((c: any, i: any) => {
                    let value = c.field ? c.field.split(".").reduce((res: any, curr: any) => res[curr], row) : "";
                    return <Col key={i}><strong>{c.header}:</strong> {c.formatter ? c.formatter(value, row) : value}
                    </Col>;
                  })
                }

              </CardBody>
            </Collapse>
          </Card>
        );
      })}

    </div>;
  }

  renderForWeb() {
    return <div className="table-responsive">
      <table className="table table-hover message-table">
        <thead>
        {this.printHeaderWeb()}
        </thead>
        <tbody>
        {this.printBodyWeb()}
        </tbody>
      </table>
    </div>;
  }
}

export default connect(mapStorageToProps, mapStorageDispatchToProps)(DataTable);
