import React from "react";
import T from "app/i18n/T";
import { connect } from "react-redux";
import {
  mapStorageDispatchToProps,
  mapStorageToProps,
} from "app/redux/mappers";
import { NavLink } from "react-router-dom";
import TokenButton from "app/app-components/dashboard/TokenButton";
import round_logo from "assets/img/round-log.svg";
import {
  IConsentDto,
  ICustomerInfoResponseDto, ILoginResponseDto,
  IOperatorInfoResponseDto,
} from "@comfortel/ebok-model";

interface CustomerInfoPropsModel {
  dashboard: {
    operatorInfo: IOperatorInfoResponseDto;
    customerInfo: ICustomerInfoResponseDto;
  };
  customerData: {
    customerConsents: {
      rows: IConsentDto[];
    };
  };
  user: ILoginResponseDto
}

const CustomerInfo = (props: CustomerInfoPropsModel) => {
  const getContactData = (type: any) => {
    return props.dashboard.customerInfo.contactData
      .filter((contact: any) => {
        return contact.type === type;
      })
      .map((contact: any) => {
        return contact.value;
      });
  };

  const getCustomerContact = (type: any, classNames: any) => {
    return getContactData(type).map((contact: any) => {
      return (
        <p key={contact} className={classNames}>
          {contact}
        </p>
      );
    });
  };

  if (
    !props.dashboard.operatorInfo ||
    !props.dashboard.customerInfo ||
    !props.customerData.customerConsents
  ) {
    return null;
  }

  let ci = props.dashboard.customerInfo;
  let oi = props.dashboard.operatorInfo;

  let einvoice =
    props.customerData.customerConsents.rows.filter(
      (it: any) => it.dictionaryConsent.identifier === "ZGODA_EFAKTURA",
    )[0].consentUsage.state === "ACQUIRED";

  return (
    <div className="bgr-w px-4 pb-4">
      <div className="justify-content-center d-flex pt-4 mb-2">
        <img
          alt=""
          src={props.user.avatar ? props.user.avatar : round_logo}
          style={{ width: "75px", height: "75px" }}
        />
      </div>
      <div className="pt-3 pb-4">
        <p className="txt-black-big mb-0">
          {ci.type === "BUSINESS"
            ? ci.personalData.companyName
            : ci.personalData.forename + " " + ci.personalData.surname}
        </p>
        {getCustomerContact("PHONE", "txt-black-big")}
        <p className="txt-grey-small mb-0">
          {T("Client number")}: {ci.number}
        </p>
        {getCustomerContact("EMAIL", "txt-grey-small")}
        <NavLink exact to="/customer/data">
          <span className=" ebok-color txt-violet-xsmall">
            {T("Edit your data")}
            <br />
          </span>
        </NavLink>
        <NavLink exact to="/customer/history">
          <span className=" ebok-color txt-violet-xsmall">
            {T("Activity history")}
            <br />
          </span>
        </NavLink>
      </div>
      <div className="top-border pb-4">
        <p className="txt-black-medium mb-0 mt-4">
          {T("Default invoice type")}:
        </p>
        <p className="txt-black-small mb-0">
          {einvoice ? T("E-Invoice") : T("Standard Invoice")}
        </p>
        <NavLink exact to="/customer/data">
          <span className="ebok-color txt-violet-xsmall mt-4">
            {T("Change")}
            <br />
          </span>
        </NavLink>
      </div>
      <div className="top-border">
        <p className="txt-black-big mb-0 mt-4">
          <strong>{T("CALL CENTER")}</strong>
        </p>
        <p className="txt-black-big">
          <strong>{oi.phoneNumber}</strong>
        </p>
      </div>
      <div className="pt-3 top-border text-center">
        <TokenButton />
      </div>
    </div>
  );
};

export default connect(
  mapStorageToProps,
  mapStorageDispatchToProps,
)(CustomerInfo);
