import React, { Component } from "react";
import { connect } from "react-redux";
import { mapStorageDispatchToProps, mapStorageToProps } from "app/redux/mappers";
import { NavLink } from "react-router-dom";
import { SecApi } from "app/api/SecApi";
// import SocialButton from "app/app-components/common/SocialButton";
// import fb_logo from "assets/img/f_logo.svg"
// import g_logo from "assets/img/g_logo.svg"
// import mojeid from "assets/img/mojeid.png";
import T from "app/i18n/T";
import { FormFeedback } from "reactstrap";
import Input from "reactstrap/lib/Input";
import { getMsg, isValid } from "../../app-components/common/Validation";
import queryString from "query-string";
import _ from "lodash";


class ViewLogIn extends Component<any, any> {


    login = (event: any) => {
        event.preventDefault();

    const data = {
      _provider: "ebok",
      _dbUser: {
        username: event.target.username.value,
        password: event.target.password.value
      }
    };

        SecApi.login(data).then(
            (json: any) => {
                this.props.setUser(json.data);
            },
            (error: any) => this.props.pushToast('e', error)
        );
    };

    handleSocialLogin = (user: any) => {
        this.setState({
            socialUser: user
        }, () => {
            SecApi.login(user).then(
                (json: any) => {
                    // this.props.pushToast('s', T('Successfully Logged in using social media'));
                    this.props.setUser(json.data);
                },
                (error: any) => this.props.pushToast('e', error)
            );
        })

  };

  handleSocialLoginFailure = () => {
    this.props.pushToast("e", "Error occurred while trying to authenticate by social media!");
  };

  componentDidMount() {
    const parsed = queryString.parse(this.props.location.search);
    if (!_.isEmpty(parsed) && parsed.PersonIdentifier && parsed.CurrentGivenName && parsed.CurrentFamilyName) {
      this.handleKIRlogin(parsed.PersonIdentifier, parsed.CurrentGivenName, parsed.CurrentFamilyName);
    }
    localStorage.removeItem("registerForm");
  }

    handleKIRlogin = (pesel: any, name: any, secondName: any) => {
        this.setState({
            socialUser: {
                _provider: "mojeid",
                _profile: {
                    email: "",
                    firstName: name,
                    id: pesel,
                    lastName: secondName,
                    name: name + " " + secondName,
                    profilePicUrl: ""
                }
            }
        }, () => {
            SecApi.login(this.state.socialUser).then(
                (json: any) => {
                    // this.props.pushToast('s', T('Successfully Logged in using social media'));
                    this.props.setUser(json.data);
                },
                (error: any) => this.props.pushToast('e', error)
            );
        })
        this.props.history.push('/')
    }


  render() {
    return (

      <>

        <div className="row d-flex justify-content-center">
          <div className="col col-12 col-sm-8 col-lg-6 col-xl-4">
            <div>

              <p className="ebok-color profile-name-card">
                {T("Log-in")}
              </p>

              <form className="form-signin mt-4" onSubmit={this.login}>
                <span className="reauth-email"> </span>
                <FormFeedback style={{ display: "block" }}>{getMsg("username")}</FormFeedback>
                <Input
                  className="form-control login-box"
                  id="username" name="username" invalid={isValid("username")} required={true}
                  placeholder={T("Login")} autoFocus={true} />
                <Input
                  className="form-control login-box"
                  type="password" id="password" name="password"
                  invalid={isValid("username")}
                  autoComplete="true"
                  required={true} placeholder={T("Password")} />
                <div className="pt-2 pb-3 justify-content-end d-flex">
                  <NavLink exact to="/forgotPassword">
                                    <span className="forgot-password pointer">
                                        {T("Forgot password?")}
                                    </span>
                  </NavLink>
                </div>
                <div className="checkbox" />

                <button className="btn btn-primary btn-block btn-lg btn-signin background-main"
                        type="submit">
                  {T("LOG IN")}
                </button>

              </form>
            </div>

            {/*{(this.props.operatorConfig.googleEnabled || this.props.operatorConfig.facebookEnabled || this.props.operatorConfig.mojeIdEnabled) && <>*/}
            {/*    <p className="txt-log-wersal mt-4 text-center">{T('OR LOG IN WITH')} </p>*/}


            {/*    <div className="d-flex justify-content-center mt-4">*/}
            {/*        {this.props.operatorConfig.googleEnabled && <SocialButton*/}
            {/*            provider='google'*/}
            {/*            appId={this.props.operatorConfig.googleId}*/}
            {/*            onLoginSuccess={this.handleSocialLogin}*/}
            {/*            onLoginFailure={this.handleSocialLoginFailure}>*/}
            {/*        <span*/}
            {/*            className={"pointer " + ((this.props.operatorConfig.facebookEnabled || this.props.operatorConfig.mojeIdEnabled) && "mr-4")}>*/}
            {/*            <img alt="" src={g_logo}/>*/}
            {/*        </span>*/}
            {/*        </SocialButton>}*/}


            {/*        {this.props.operatorConfig.facebookEnabled && <SocialButton*/}
            {/*            provider='facebook'*/}
            {/*            appId={this.props.operatorConfig.facebookId}*/}
            {/*            onLoginSuccess={this.handleSocialLogin}*/}
            {/*            onLoginFailure={this.handleSocialLoginFailure}>*/}
            {/*        <span className={"pointer " + ((this.props.operatorConfig.mojeIdEnabled) && "mr-4")}>*/}
            {/*            <img alt="" src={fb_logo}/>*/}
            {/*        </span>*/}
            {/*        </SocialButton>*/}
            {/*        }*/}

            {/*        {this.props.operatorConfig.mojeIdEnabled && <span className="pointer">*/}
            {/*            <img alt=""*/}
            {/*                 onClick={() => window.open(this.props.operatorConfig.mojeIdMie + "/app?gotoURL=" + encodeURIComponent(window.location.href), "_self")}*/}
            {/*                 src={mojeid}/>*/}
            {/*    </span>*/}
            {/*        }*/}

            {/*    </div>*/}
            {/*</>*/}
            {/*}*/}

            <div className="mt-3 w-100 d-inline-flex align-items-center">
              <div className="grey-stripe w-100" />
              <p className="txt-log-wersal mt-4 text-center mb-4 px-3">
                {T("OR")}
              </p>
              <div className="grey-stripe w-100" />
            </div>

            <NavLink exact to="/registration"
                     className="ebok-color btn-border-color btn-reg w-100 d-flex justify-content-center align-items-center pointer">
              {T("SIGN UP")}
            </NavLink>
          </div>
        </div>

      </>

    );
  }


}


export default connect(mapStorageToProps, mapStorageDispatchToProps)(ViewLogIn);
