import React, {Component} from 'react';
import 'assets/css/table.css'
import {connect} from "react-redux";
import {mapStorageDispatchToProps, mapStorageToProps} from "app/redux/mappers";
import DtFilterSimple from "./DtFilterSimple";
import {Button} from "reactstrap";
import DtFilterDate from "./DtFilterDate";
import DtFilterSelect from "./DtFilterSelect";


class DtFilters extends Component<any, any> {

    constructor(props: any) {
        super(props);
        this.state = {showFilters: false};
    }

    dataTable = () => {
        return this.props.dataTable[this.props.id]
    };

    dataTableParams = () => {
        return this.props.dataTable[this.props.id + "_params"]
    };

    toggleShowFilters() {
        this.setState({showFilters: !this.state.showFilters});
    }

    search() {
        this.props.getData()
    }

    renderFilters() {
        if (!this.state.showFilters) {
            return '';
        }
        return <>
            <div className="d-flex flex-wrap">
                {this.props.filters.map((f: any, i: any) => {
                    return <React.Fragment key={i}>
                        {this.printFilter.bind(this)(f)}
                    </React.Fragment>
                })}
            </div>

            <div className="d-flex mr-3 p-2 justify-content-end mb-2">
                <Button outline color="primary" size="sm" onClick={() => this.clearAllFilters()} className="mr-3">
                    <i className="far fa-eraser"/> {this.props.labels.clear}
                </Button>
                <Button outline color="primary" size="sm" onClick={() => this.search()}>
                    <i className="far fa-search"/> {this.props.labels.search}
                </Button>
            </div>

        </>
    }

    printFilter(filter: any) {
        switch (filter.type) {
            case "DATE":
                return <DtFilterDate filter={filter} id={this.props.id}/>;
            case "SELECT":
                return <DtFilterSelect filter={filter} id={this.props.id}/>;
            default:
                return <DtFilterSimple filter={filter} id={this.props.id}/>;
        }
    }

    clearAllFilters() {
        let newParams = this.dataTableParams();
        newParams.filters = {};
        this.props.setDataTableParams(newParams, this.props.id);
    }

    countFilters() {
        let filters = this.dataTableParams().filters;
        return Object.keys(filters ? filters : {}).filter(f => filters[f].value).length;
    }

    render() {
        return <>
            <div className="d-flex" style={{paddingTop: '10px', paddingBottom: '10px'}}>
                <div className="d-inline" style={{marginLeft: '10px'}}>
                    <span className="pointer" onClick={this.toggleShowFilters.bind(this)}>
                          <i className="far fa-filter ebok-color"/>
                        {this.state.showFilters ? ' ' + this.props.labels.hideFilters : ' ' + this.props.labels.showFilters}</span>
                </div>
                {!this.state.showFilters && this.countFilters() ?
                    <div className="message d-inline background-main" style={{marginLeft: '5px', position: 'static'}}>
                        <p className="p-0">
                            {this.countFilters()}
                        </p>
                    </div> : ''}
            </div>
            {this.renderFilters()}
        </>
    }
}

export default connect(mapStorageToProps, mapStorageDispatchToProps)(DtFilters);
