/**
 * Prawa autorskie: Comfortel Sp. z o.o ul. Dojazdowa 9b 43-100 Tychy Polska
 * Wszelkie prawa zastrzeżone.
 *
 * Created by mtomczyk on 25.11.2019.
 */

export class ReplaceInStringHelper {
  public static replace(mappedString: string, values: { [key: string]: string }): string {
    return Object.keys(values).reduce((previousValue, currentValue) => {
      return previousValue.replace(`${currentValue}`, values[currentValue]);
    }, mappedString);
  }
}
