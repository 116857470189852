import { Api, BASIC_PORT } from "./Api";
import { Operator$CONFIG, Operator$CONTACT, Operator$OPERAPI, Theme$THEME } from "@comfortel/ebok-model/constants";

export class OperApi extends Api {


	static apiUrl = (window.location.port === BASIC_PORT && (window as any).var.OPERAPI_URL) || Operator$OPERAPI;

	static theme() {
		return OperApi.get(Theme$THEME)
	}

    static getOperatorContact() {
        return OperApi.get(Operator$CONTACT)
    }

	static getOperatorConfig() {
		return OperApi.get(Operator$CONFIG)
	}

	static getArticle(articleName: any, lang: string) {
		return OperApi.get('/article/' + articleName + "/" + lang)
	}


}
