import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import "assets/css/registration.css";
import { mapStorageDispatchToProps, mapStorageToProps } from "app/redux/mappers";
import { SecApi } from "app/api/SecApi";
import ActionConfirmation from "app/app-components/common/ActionConfirmation";
import { RegistrationStep1 } from "app/app-components/register/RegistrationStep1";
import { RegistrationStep2 } from "app/app-components/register/RegistrationStep2";
import { RegisterButton } from "app/app-components/register/RegisterButton";
import { MailRegister } from "app/app-components/register/MailRegister";
import { SocialInfo } from "app/app-components/register/SocialInfo";
import T from "app/i18n/T";
import Logger from "app/Logger";
import queryString from "query-string";
import { RegistrationStepWithoutSocial } from "../../app-components/register/RegistrationStepWithoutSocial";

class ViewRegistration extends Component<any, any> {
  // INFO Logowanie przez FV/google zostało wyłączone.
  public nodes: any;
  public userData: any;

  constructor(props: any) {
    super(props);
    this.state = {
      registerSuccess: null,
      message: "",
      registerPossible: false,
      registerForm: {
        crmNumber: "",
        crmToken: "",
        password: "",
        repeatPassword: "",
        loginType: "FORM",
        rulesCheckbox: true,
        loginInfo: {}
      }
    };
    this.nodes = {};
    this.sendForm = this.sendForm.bind(this);
  }

  componentDidMount() {
    this.userData = JSON.parse((localStorage as any).getItem("registerForm"));
    if (localStorage.getItem("registerForm")) {
      this.setState({
        registerForm: {
          crmNumber: this.userData.crmNumber,
          crmToken: this.userData.crmToken,
          password: this.userData.password,
          repeatPassword: this.userData.repeatPassword,
          loginType: "FORM",
          loginInfo: this.userData.loginInfo,
          rulesCheckbox: true
        }

      });
    } else {
      this.setState({
          registerForm: {
            crmNumber: "",
            crmToken: "",
            password: "",
            repeatPassword: "",
            loginType: "FORM",
            loginInfo: {},
            rulesCheckbox: true
          }
        }
      );
    }
    const parsed = queryString.parse(this.props.location.search);
    if (!_.isEmpty(parsed) && parsed.PersonIdentifier && parsed.CurrentGivenName && parsed.CurrentFamilyName) {
      this.handleKIRlogin(parsed.PersonIdentifier, parsed.CurrentGivenName, parsed.CurrentFamilyName);
      this.props.history.push("/registration");
    }
  }

  componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {
    localStorage.setItem("registerForm", JSON.stringify(prevState.registerForm));
  }

  updateFormState = (e: any) => {
    e.persist();
    this.setState((preState: any) => ({
      ...preState,
      registerForm: {
        ...preState.registerForm,
        [e.target.id]: e.target.type === "checkbox" ? e.target.checked : e.target.value
      }
    }), this.validateForm);
  };

  provideEmail = (e: any) => {
    e.persist();
    this.setState((preState: any) => ({
      ...preState,
      registerForm: {
        ...preState.registerForm,
        loginInfo: { email: e.target.value }
      }
    }), this.validateForm);
  };

  validateForm = () => {
    let formValues = this.state.registerForm;
    if (formValues.crmNumber && formValues.crmToken && formValues.password && formValues.repeatPassword) {
      this.setState({ registerPossible: true });
    } else {
      this.setState({ registerPossible: false });
    }
  };

  validateResponse = (response: any) => {

    if (response.meta.status === "SUCCESS") {
      this.setState((prevState: any) => ({
        registerSuccess: true,
        title: "Zostałeś poprawnie zarejestrowany",
        message: prevState.registerForm.loginType === "FORM" ? T("You have been registered. Token to activate your account was sent to mail provided in form.") : T("You have been registered using social media. Now you can Log In"),
        loginButton: true
      }));
    }

    this.props.setValidation(response.meta.validation);


  };

  sendForm() {
    let data = this.state.registerForm;
    this.validateForm();
    SecApi.register(data).then((json: any) => {
        this.validateResponse(json);
      },
      (error: any) => console.error(error));
  }

  selectMail = () => {
    this.logout();
    this.setState((oldState: any) => ({
      ...oldState,
      registerForm: {
        ...oldState.registerForm,
        loginType: "FORM",
        loginInfo: {}
      }
    }), this.validateForm);
  };

  handleSocialLogin = (user: any) => {
    this.logout();
    this.setState((oldState: any) => ({
      ...oldState,
      registerForm: {
        ...oldState.registerForm,
        loginType: _.toUpper(user._provider),
        loginInfo: user
      }
    }), this.validateForm);
  };

  handleKIRlogin = (pesel: any, name: any, secondName: any) => {
    Logger.debug("Zalogowano", pesel, name, secondName);
    this.setState((oldState: any) => ({
      ...oldState,
      registerForm: {
        ...oldState.registerForm,
        loginType: "MOJEID",
        loginInfo: {
          _profile: {
            email: "",
            firstName: name,
            id: pesel,
            lastName: secondName,
            name: name + " " + secondName,
            profilePicUrl: ""
          }
        }
      }
    }), this.validateForm);

  };

  handleSocialLoginFailure = (err: any) => {
    Logger.error(err);
  };

  setNodeRef(provider: any, node: any) {
    if (node) {
      this.nodes[provider] = node;
    }
  }

  onLogoutSuccess = () => {

  };

  logout = () => {
    if (this.state.registerForm.loginInfo) {
      let currentProvider = this.state.registerForm.loginInfo._provider;

      this.setState((oldState: any) => ({
        ...oldState,
        registerForm: {
          ...oldState.registerForm,
          loginInfo: {}
        }
      }));
      if (currentProvider) {
        this.nodes[currentProvider].props.triggerLogout();
      }
    }
  };


  render() {
    // let loginInfo = this.state.registerForm;

    if (this.state.registerSuccess !== null) {
      return <ActionConfirmation success={this.state.registerSuccess}
                                 message={this.state.message}
                                 title={this.state.title}
                                 loginButton={this.state.loginButton} />;

    } else {
      return <>

        <div className="row">
          <div className="col mb-4">
            <p className="ebok-color profile-name-card">{T("Sign-up")}</p>
          </div>
        </div>

        <div className="row">
          <RegistrationStep1 onChange={this.updateFormState}
                             values={this.state.registerForm} />
          <RegistrationStep2 onChange={this.updateFormState}
                             values={this.state.registerForm} />
          {/*<RegistrationStep3 onClick={this.selectMail}*/}
          {/*                   onLoginSuccess={this.handleSocialLogin}*/}
          {/*                   onLoginFailure={this.handleSocialLoginFailure}*/}
          {/*                   onLogoutSuccess={this.onLogoutSuccess}*/}
          {/*                   nodeRef={this.setNodeRef.bind(this, 'google')}*/}
          {/*                   nodeRef1={this.setNodeRef.bind(this, 'facebook')}/>*/}
          <RegistrationStepWithoutSocial onChange={this.provideEmail} />
        </div>

        <div className="row">
          <div className="col col-12 col-md-6 col-xl-4 mb-5 px-3 px-lg-5 offset-xl-8">
            {/*<RegisterButton registerPossible={this.state.registerPossible} onClick={this.sendForm}*/}
            {/*                onChange={this.updateFormState} />*/}
            {/*{this.renderSocialForm(loginInfo)}*/}

            {/*{this.renderMailForm()}*/}

            {this.renderRegisterButton()}

          </div>
        </div>
      </>;
    }
  }

  renderRegisterButton() {
    if (this.state.registerForm.loginType) {
      return <RegisterButton registerPossible={this.state.registerPossible} onClick={this.sendForm}
                             onChange={this.updateFormState} />;
    }
  }

  renderMailForm() {
    if (this.state.registerForm.loginType === "FORM") {
      return <MailRegister onChange={this.provideEmail} />;
    }
  }

  renderSocialForm(loginInfo: any) {
    if (["GOOGLE", "FACEBOOK", "MOJEID"].includes(this.state.registerForm.loginType)) {
      return <SocialInfo loginInfo={loginInfo} />;
    }
  }
}

export default connect(mapStorageToProps, mapStorageDispatchToProps)(ViewRegistration);
