import { IMetaDto } from "@comfortel/ebok-model";
export interface ApiResponse<T> {
  meta: IMetaDto;
  data?: T;
}

export const CUSTOMER = "/customer"
export const PAYMENT = "/payment"
export const MESSAGES = "/messages"
export const DEV = "/dev"
