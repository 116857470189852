import React, { Component } from "react";
import { connect } from "react-redux";
import {
  mapStorageDispatchToProps,
  mapStorageToProps,
} from "app/redux/mappers";
import { CustApi } from "app/api/CustApi";
import CustomerData from "app/app-components/myData/CustomerData";
import CustomerContact from "app/app-components/myData/CustomerContact";
import CustomerConsents from "app/app-components/myData/CustomerConsents";
import CustomerInvoiceType from "app/app-components/myData/CustomerInvoiceType";
import {
  IConsentDto,
  IConsentsResponseDto,
  ICustomerInfoResponseDto,
} from "@comfortel/ebok-model";
import { ApiResponse } from "../../models/ApiModels";

interface ViewCustomerDataProps {
  setCustomerData: (data: ICustomerInfoResponseDto) => void;
  setCustomerInfo: (data: ICustomerInfoResponseDto) => void;
  setCustomerConsents: (data: IConsentsResponseDto) => void;
  customerData: {
    customerConsents: {
      rows: IConsentDto[];
    };
  };
  dashboard: {
    customerInfo: ICustomerInfoResponseDto;
  };
}

class ViewCustomerData extends Component<ViewCustomerDataProps> {
  componentDidMount() {
    CustApi.customerInfo().then(
      (r: ApiResponse<ICustomerInfoResponseDto>) => {
        if (r.data) {
          this.props.setCustomerData(r.data);
          this.props.setCustomerInfo(r.data);
        }
      },
      (error: any) => console.error(error),
    );

    if (!this.props.customerData.customerConsents) {
      CustApi.customerConsents().then(
        (r: ApiResponse<IConsentsResponseDto>) => {
          if (r.data) {
            this.props.setCustomerConsents(r.data);
          }
        },
        (error: any) => console.error(error),
      );
    }
  }

  render() {
    return this.props.dashboard.customerInfo ? (
      <div className="container bgr-grey">
        <div className="row pt-2 px-1 px-md-0">
          <CustomerData />
          <div className="col px-0 px-md-3 py-3">
            <CustomerContact />
            <CustomerInvoiceType />
            <CustomerConsents />
          </div>
        </div>
      </div>
    ) : (
      ""
    );
  }
}

export default connect(
  mapStorageToProps,
  mapStorageDispatchToProps,
)(ViewCustomerData);
