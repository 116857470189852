import React, { Component } from "react";
import round_logo from "../../../assets/img/round-log.svg";
import { connect } from "react-redux";
import {
  mapStorageDispatchToProps,
  mapStorageToProps,
} from "app/redux/mappers";
import T from "../../i18n/T";
import {
  ICustomerInfoResponseDto,
  ILoginResponseDto,
} from "@comfortel/ebok-model";

interface CustomerDataPropsModel {
  dashboard: {
    customerInfo: ICustomerInfoResponseDto;
  };
  user: ILoginResponseDto;
}

class CustomerData extends Component<CustomerDataPropsModel> {
  render() {
    let customerInfo = this.props.dashboard.customerInfo;
    if (!customerInfo) {
      return <></>;
    }
    return (
      <div className="col col-12 col-md-6 col-xl-4 px-0 px-md-3 py-3">
        <div className="bgr-w px-4 pb-4">
          <div className="pt-3 pb-3">
            <p className="txt-violet-big ebok-color mb-0">{T("Client data")}</p>
          </div>
          <div className="justify-content-center d-flex pt-4 mb-2">
            <img
              alt=""
              src={this.props.user.avatar ? this.props.user.avatar : round_logo}
              style={{ width: "75px", height: "75px" }}
            />
          </div>
          <div className="pt-3 pb-4">
            <p className="txt-black-big mb-0 text-center">
              {customerInfo.type === "INDIVIDUAL"
                ? `${customerInfo.personalData.forename} ${customerInfo.personalData.surname}`
                : `${customerInfo.personalData.companyName}`}
            </p>

            <p className="txt-black-big text-center">
              {
                customerInfo.contactData.filter((contact: any) => {
                  return contact.type === "PHONE";
                })[0].value
              }
            </p>
          </div>
          <div className="row pb-3">
            <div className="col">
              <p className="txt-black-medium mb-0">
                <strong>{T("Client number")}</strong>
                <br />
              </p>
            </div>
            <div className="col pl-0">
              <p className="txt-black-small mb-0 pr-1 txt-l-r-2">
                {customerInfo.number}
                <br />
              </p>
            </div>
          </div>
          {customerInfo.type === "INDIVIDUAL" ? (
            <div className="row pb-3">
              <div className="col">
                <p className="txt-black-medium mb-0">
                  <strong>PESEL:</strong>
                  <br />
                </p>
              </div>
              <div className="col pl-0">
                <p className="txt-black-small mb-0 pr-0 txt-l-r-2">
                  {customerInfo.identifications.pesel}
                  <br />
                </p>
              </div>
            </div>
          ) : (
            <>
              <div className="row pb-3">
                <div className="col">
                  <p className="txt-black-medium mb-0">
                    <strong>{T("Company name")}:</strong>
                    <br />
                  </p>
                </div>
                <div className="col pl-0">
                  <p className="txt-black-small mb-0 pr-0 txt-l-r-2">
                    {customerInfo.personalData.companyName}
                    <br />
                  </p>
                </div>
              </div>
              <div className="row pb-3">
                <div className="col">
                  <p className="txt-black-medium mb-0">
                    <strong>NIP:</strong>
                    <br />
                  </p>
                </div>
                <div className="col pl-0">
                  <p className="txt-black-small mb-0 pr-1 txt-l-r-2">
                    {customerInfo.identifications.nip}
                    <br />
                  </p>
                </div>
              </div>
              <div className="row pb-3">
                <div className="col">
                  <p className="txt-black-medium mb-0">
                    <strong>Regon:</strong>
                    <br />
                  </p>
                </div>
                <div className="col pl-0">
                  <p className="txt-black-small mb-0 pr-1 txt-l-r-2">
                    {customerInfo.identifications.regon}
                    <br />
                  </p>
                </div>
              </div>
            </>
          )}

          <div className="row pb-3">
            <div className="col">
              <p className="txt-black-medium mb-0">
                <strong>{T("Address")}:</strong>
                <br />
              </p>
            </div>
            <div className="col pl-0">
              <p className="txt-black-small mb-0 pr-1 txt-l-r-2">
                {customerInfo.correspondenceData.address.street}{" "}
                {customerInfo.correspondenceData.address.number}
                <br /> {customerInfo.correspondenceData.address.postCode}{" "}
                {customerInfo.correspondenceData.address.post}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  mapStorageToProps,
  mapStorageDispatchToProps,
)(CustomerData);
