import { Api, BASIC_PORT } from "./Api";
import {
  Consultant$CONSULTANT_REGISTER, Sec$SEC_API, Settings$LOGIN,
  Theme$THEME,
  User$USER,
  User$USER_CONTACT_TOKEN_GENERATE,
  User$USER_PASSWORD_CHANGE,
  User$USER_PASSWORD_FORGET,
  User$USER_REGISTER,
} from "@comfortel/ebok-model/constants";

export class SecApi extends Api {
  static apiUrl =
    (window.location.port === BASIC_PORT && (window as any).var.SECAPI_URL) ||
    Sec$SEC_API;

  static theme() {
    return SecApi.get(Theme$THEME);
  }

  static user() {
    return SecApi.get(User$USER);
  }

  static login(credentials: any) {
    return SecApi.post(Settings$LOGIN, credentials);
  }

  static logout() {
    return SecApi.getForLogout("/logout", null);
  }

  static register(registerData: any) {
    return SecApi.post(User$USER_REGISTER, registerData);
  }

  static registerConsultant(registerData: any) {
    return SecApi.post(Consultant$CONSULTANT_REGISTER, registerData);
  }

  static verifyToken(token: any) {
    return SecApi.get("/user/register/confirm/" + token);
  }

  static generateToken() {
    return SecApi.get(User$USER_CONTACT_TOKEN_GENERATE);
  }

  static resetPassword(resetData: any) {
    return SecApi.post(User$USER_PASSWORD_FORGET, resetData);
  }

  static resetPasswordCheckToken(token: any) {
    return SecApi.post("/user/password/checkToken/" + token);
  }

  static resetPasswordConfirm(confirmData: any) {
    return SecApi.post(User$USER_PASSWORD_CHANGE, confirmData);
  }
}
