import React, {Component} from 'react';
import "assets/css/pie-spinner.css"

export class PieSpinner extends Component< any, any> {
    public btnRef: any
    constructor(props: any) {
        super(props);
        this.btnRef = React.createRef();
    }


    componentDidMount() {
        const canvas = this.btnRef.current;
        const ctx = canvas.getContext("2d");

        let style = getComputedStyle((document as any).querySelector('.ebok-color'));

        const color = style.color;

        const time = 5000;

        const beginAngle = Math.PI * 1.5;
        let endAngle = Math.PI * 1.5;

        ctx.beginPath();
        ctx.fillStyle = color;
        ctx.moveTo(8, 8);
        ctx.strokeStyle = color;
        ctx.lineWidth = 0.5;

        let interval = setInterval(() => {
            endAngle = endAngle + 2 * Math.PI * 0.05;
            ctx.arc(8, 8, 7, beginAngle, endAngle);
            ctx.lineTo(8, 8);
            ctx.stroke();
            ctx.fill();

        }, time / 20);

        setTimeout(() => {
            clearInterval(interval);
        }, time + 100)

    }

    render() {
        return <>
            <canvas style={{marginLeft: "-3px", marginRight: "-5px", marginTop: "5px"}}
                    ref={this.btnRef}
                    width="17"
                    height="17"/>
            <span className="ebok-color" style={{display: 'none'}}/>
        </>
    }
}
