import React from "react";
import T from "app/i18n/T";
import { connect } from "react-redux";
import { mapStorageDispatchToProps, mapStorageToProps } from "app/redux/mappers";
import { NavLink } from "react-router-dom";
import Utils from "app/Utils";
import { IChargingPeriodDto } from "@comfortel/ebok-model";

const NoPaymentsData = () => {
  return (
    <div className="row pt-0">
      <div className="col col-12 col-lg-8">
        <div className="row dane-mb">
          <div className="col col-6">
            <p className="txt-black-medium mb-0">
              <strong>{T("Last bill")}:</strong>
            </p>
          </div>
          <div className="col">
            <p className="txt-black-small mb-0">{T("None")}</p>
          </div>
        </div>
      </div>
      <div className="col">
        <div>
          <div className="mb-auto">
            <div className="row d-flex align-items-center">
              <div className="col col-6 col-lg-4">
                <p className="txt-black-medium">
                  <strong>{T("Balance")}:</strong>
                </p>
              </div>
              <div className="col col-6 col-lg-8">
                <p className="ebok-color txt-violet-vbig">
                  <strong>0.00 zł</strong>
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Balance = ({ balances } : any) => {
  return <div>
    <div className="mb-auto">
      <div className="row d-flex align-items-center">
        <div className="col col-6 col-lg-4">
          <p className="txt-black-medium"><strong>{T("Balance")}:</strong></p>
        </div>
        <div className="col col-6 col-lg-8">
          <p
            className={`${balances.expiredBalance > 0 ? "red-color" : "ebok-color"} txt-violet-vbig`}>
            <strong>{Utils.addPrecision(balances.balance)} zł</strong><br />
          </p>
        </div>
      </div>
    </div>
    <div>
      {(balances.balance > 0) ?
        <NavLink exact to="/payments"
                 className="btn btn-primary btn-block btn-lg btn-signin-thin background-main">
          {T("PAY")}
        </NavLink> : null}
    </div>
  </div>;
};

const ArrearsBalance = ({ balances }: any) => {
  return <div className="row dane-mb">
    <div className="col col-6">
      <p className="txt-red-medium mb-0"><strong>{T("Arrears")}:</strong><br />
      </p>
    </div>
    <div className="col">
      <p
        className="txt-red-medium mb-0">{Utils.addPrecision(balances.expiredBalance)} zł</p>
    </div>
  </div>;
};

const ExcessBalance = ({ balances }: any) => {
  return <div className="row dane-mb">
    <div className="col col-6">
      <p className="txt-black-medium mb-0"><strong>{T("Excess payment")}:</strong><br />
      </p>
    </div>
    <div className="col">
      <p
        className="txt-black-small mb-0">{Utils.addPrecision(Math.abs(balances.balance))} zł</p>
    </div>
  </div>;
};

const EmptyInvoiceData = () => {
  return <div className="row dane-mb">
    <div className="col col-6">
      <p className="txt-black-medium mb-0"><strong>{T("Last bill")}:</strong></p>
    </div>
    <div className="col">
      <p className="txt-black-small mb-0">{T("None")}</p>
    </div>
  </div>;
};

const InvoiceData = ({ invoice } : any) => {
  return <>
    <div className="row dane-mb">
      <div className="col col-6">
        <p className="txt-black-medium mb-0"><strong>{T("Last bill")}:</strong></p>
      </div>
      <div className="col">
        <p className="txt-black-small mb-0">{Utils.addPrecision(invoice.grossAmount)} zł</p>
      </div>
    </div>
    <div className="row dane-mb">
      <div className="col col-6">
        <p className="txt-black-medium mb-0"><strong>{T("Status")}:</strong><br /></p>
      </div>
      <div className="col">
        <p className="txt-black-small mb-0">{invoice.isPaid ? "Opłacona" : "Nieopłacona"}</p>
      </div>
    </div>
    <div className="row dane-mb">
      <div className="col col-6">
        <p className="txt-black-medium mb-0"><strong>{T("Billing period")}:</strong><br /></p>
      </div>
      <div className="col">
        {invoice.chargingPeriods.map((chargingPeriod: IChargingPeriodDto) =>
          <p className="txt-black-small mb-0">
            {T("from")}: {Utils.getFormattedDate(chargingPeriod.billingCycleStart, "DD.MM.YYYY")}
            <br />
            {T("to")}: {Utils.getFormattedDate(chargingPeriod.billingCycleEnd, "DD.MM.YYYY")}
          </p>
        )}
      </div>
    </div>
  </>;
};

const Payments = (props: any) => {
  let payment = props.dashboard.customerPayment;

  return (
    <div className="bgr-w h-100 px-4 pb-4">
      <div className="pt-3 pb-3">
        <p className="ebok-color txt-violet-big mb-0">{T("Your payments")}</p>
      </div>
      {(props.dashboard.customerInfo && payment) ? <div className="row pt-0">
          <div className="col col-12 col-lg-8">
            {payment.settlement ? <InvoiceData invoice={payment.settlement} /> : <EmptyInvoiceData />}
              {(payment.financialStatus.balance < 0) ?
                <ExcessBalance balances={payment.financialStatus} /> : null}
              {(payment.financialStatus.expiredBalance > 0) ?
                <ArrearsBalance balances={payment.financialStatus} /> : null}
            </div>
            <div className="col">
              <Balance balances={payment.financialStatus} />
            </div>
          </div> :
          <NoPaymentsData />}
      </div>
    );
}

export default connect(mapStorageToProps, mapStorageDispatchToProps)(Payments);
