import { Route, RouteProps } from "react-router-dom";
import React from "react";
import { connect } from "react-redux";
import { mapStorageToProps } from "app/redux/mappers";
import { Alert } from "reactstrap";

interface AppSecureRouteProps extends RouteProps {
  component: React.ComponentType<any>;
  user: any,
  id?: string,
}

const AppSecureRoute: React.FC<AppSecureRouteProps> = ({ component: Component, ...rest }) => {

  return (
    <div>
      <Route {...rest} render={(props) => {
        return (
          rest.user
            ? <Component {...props} />
            : <Alert color="warning"><i className="fas fa-exclamation-circle" /> Insufficient privileges.
              Please contact your Administrator.</Alert>
        );
      }} />
    </div>
  );
};

export default connect(mapStorageToProps)(AppSecureRoute);
