import React, {Component} from 'react';
import {connect} from "react-redux";
import {mapStorageDispatchToProps, mapStorageToProps} from "app/redux/mappers";
import {dismissToast, store} from "app/redux/redux";
import {Toast, ToastHeader, ToastBody} from "reactstrap";
import T from "app/i18n/T";
import Logger from "app/Logger";

class Toasts extends Component<any> {

    toggleAlert = (toast: any) => {
        return () => {
            Logger.debug(toast);
            store.dispatch(dismissToast(toast))
        }
    };

    render() {
        return <div style={{top: 20, left: 5, position: 'fixed', zIndex:1000000}}>
            <div style={{overflow: "visible"}}>
                {this.props.toast.map((toast: any, key: number) => {

                    // return <Alert fade key={toast.timestamp} toggle={this.toggleAlert(toast)}
                    //               color={toast.type === "e" ? "danger" : "success"}>{toast.msg}</Alert>

                    return <Toast key={key}>
                        <ToastHeader icon={toast.type === "e" ? "danger" : "success"} toggle={this.toggleAlert(toast)}>
                            {toast.type === "e" ? T('Error') : T('Information')}
                        </ToastHeader>
                        <ToastBody>
                            {toast.msg}
                        </ToastBody>
                    </Toast>
                })}
            </div>
        </div>
    }
}

export default connect(mapStorageToProps, mapStorageDispatchToProps)(Toasts)
