import React, {Component} from 'react';
import 'assets/css/table.css'
import {connect} from "react-redux";
import {mapStorageDispatchToProps, mapStorageToProps} from "app/redux/mappers";
import {FormGroup} from "reactstrap";
import {DateTimePicker} from "react-widgets";


class DtFilterDate extends Component<any, any> {

    constructor(props: any) {
        super(props);
        this.state = {
            value: this.getValue()
        };
    }

    dataTableParams = () => {
        return this.props.dataTable[this.props.id + "_params"]
    };

    changeValue(value: any) {
        let newParams = this.dataTableParams();
        newParams.filters[this.props.filter.field + this.props.filter.operation] = {
            value: value,
            field: this.props.filter.field,
            operation: this.props.filter.operation,
            type: this.props.filter.type
        };
        this.props.setDataTableParams(newParams, this.props.id);
        this.setState({value: value});

    }

    getValue() {
        if (this.dataTableParams().filters[this.props.filter.field + this.props.filter.operation]) {
            return this.dataTableParams().filters[this.props.filter.field + this.props.filter.operation].value
        } else {
            return null;
        }
    }

    componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {
        if (this.getValue() !== this.state.value && this.getValue() === null) {
            this.setState({value: null})
        }
    }

    render() {
        return <div style={{marginLeft: '10px'}}><p
            style={{marginBottom: '0px', fontSize: '0.875em'}}>{this.props.filter.label}</p>
            <FormGroup>
                <DateTimePicker
                    format={'YYYY-MM-DD'}
                    time={false}
                    value={this.state.value}
                    onChange={this.changeValue.bind(this)}
                />
            </FormGroup>
        </div>

    }
}

export default connect(mapStorageToProps, mapStorageDispatchToProps)(DtFilterDate);
