import React, {Component} from 'react';
import {Button} from "reactstrap";
import {cloneDeep} from "lodash";
import "assets/css/debug.css"
import {DevApi} from "app/api/DevApi";

export class DebugSidebar extends Component<any, any, any> {

    constructor(props: any) {
        super(props);

        this.state = {
            debug: this.props.debug,
            btnStyle: {
                position: 'fixed',
                bottom: 20,
                left: -5,
                width: 47,
                textAlign: 'left',
                zIndex: 10000000
            },
            btnUserCacheStyle: {
                position: 'fixed',
                bottom: 60,
                left: -5,
                width: 100,
                textAlign: 'left',
                zIndex: 10000000
            },
            btnAllCacheStyle: {
                position: 'fixed',
                bottom: 100,
                left: -5,
                width: 100,
                textAlign: 'left',
                zIndex: 10000000
            },
            cleanUserCacheButtonText: "Client",
            sidebarStyle: {
                position: 'fixed',
                bottom: 0,
                right: -2,
                width: 0,
                zIndex: 10000001,
                backgroundColor: '#464646',
            },
            isOpen: false
        };

        this.toggle = this.toggle.bind(this)
    }

/*    setCleanUserCacheButtonText(text, timeout = 0) {
        setTimeout(() => {
            this.setState({
                cleanUserCacheButtonText: text
            });
        }, timeout);
    }*/

    toggle() {
        let state = cloneDeep(this.state);
        if (state.isOpen) {
            state.btnStyle.right = -8;
            state.sidebarStyle.width = 0;
            (state.isOpen as any) = false
        } else {
            state.btnStyle.right = 492;
            state.sidebarStyle.width = 500;
            (state.isOpen as any) = true
        }
        this.setState(state)
    }

    cleanCache = () => {
        DevApi.clearCacheForCurrentUser().then(
          (response: any) => {},
          (error: any) => {}
        )
    };

    cleanAllCache = () => {
        DevApi.clearAllCache().then(
          (response: any) => {},
          (error: any) => {}
        )
    };

    render() {
        return (window as any).var.DEV_MODE ? <>
            <div className='debug-sidebar'>
                <Button color="danger" style={this.state.btnStyle} onClick={this.toggle}><i
                    className="fa fa-bug"/></Button>

                <div style={this.state.sidebarStyle} className="card">
                    <div className="card-body" id="debug" style={{
                        overflow: 'scroll',
                        maxHeight: 'calc(100vh - 90px)',
                        fontSize: 11,
                        color: '#fff'
                    }}/>
                </div>
            </div>
            <div>
                <Button
                    color="success"
                    style={this.state.btnUserCacheStyle}
                    onClick={this.cleanCache}>
                    <i className="fas fa-trash-alt"/> CLIENT
                </Button>
                <Button
                    color="success"
                    style={this.state.btnAllCacheStyle}
                    onClick={this.cleanAllCache}>
                    <i className="fas fa-trash-alt"/> ALL
                </Button>
            </div>
        </> : null
    }
}
