import T from "app/i18n/T";
import React from "react";
import { FormFeedback, FormGroup, Input } from "reactstrap";
import { getMsg, isValid } from "app/app-components/common/Validation";

export const RegistrationStep2 = (props: any) => <div
  className="col step-box-stripe-v2 col-12 col-md-6 col-xl-4 px-3 px-lg-5 mt-3 pt-4 pt-md-0 pb-4">
  <div className="d-lg-flex align-items-lg-start h-25">
    <div className="row">
      <div className="col col-12">
        <div className="icon-w-text">
          <div>
            <p className="circle-number">2</p>
            <i className="fas fa-lg fa-circle ebok-color" />
          </div>
          <div>
            <p className="step-txt pl-2">
              {T("Set password (required 8 characters, with at least one upper case letter and digit)")}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <FormGroup>
    <Input id="password" type="password" onChange={props.onChange}
           className="w-100 txt-input form-control login-box"
           autoComplete="true"
           value={props.values.password}
           placeholder={T("Password")} invalid={isValid("password")} />
    <FormFeedback>{getMsg("password")}</FormFeedback>
  </FormGroup>
  <FormGroup>
    <Input id="repeatPassword" type="password" onChange={props.onChange}
           className="w-100 txt-input form-control login-box"
           value={props.values.repeatPassword}
           placeholder={T("Repeat password")} invalid={isValid("repeatPassword")}
           autoComplete="true" />
    <FormFeedback>{getMsg("repeatPassword")}</FormFeedback>
  </FormGroup>
</div>;
