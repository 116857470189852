import qs from "query-string";
import { ajaxStart, ajaxStop, clearUser, store } from "app/redux/redux";
import T from "app/i18n/T";
import Logger from "app/Logger";
import { setUnavailable, setValidation } from "../redux/redux";

export const BASIC_PORT = "3000"

export class Api {
  static apiUrl = "";
  static localUrl = `http://localhost:${BASIC_PORT}/api`;
  public p: any;
  public store: any;
  private ignoreError: Boolean;

  constructor(promise: any, ignoreError = false) {
    this.p = promise;
    this.ignoreError = ignoreError;
    this.store = {};
  }

  static fetch(url: string, options: any) {
    store.dispatch(ajaxStart());
    return fetch(this.apiUrl + url, options)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }
        return response.json();
      })
      .then((data) => data)
      .catch((error) => {
        throw error;
      });
  }

  static get(url: string, local = false) {
    store.dispatch(ajaxStart());
    Logger.debug(`GET [${url}]`);
    return new this(
      fetch((local ? this.localUrl : this.apiUrl) + url, {
        credentials: "include",
      }),
    );
  }

  static getCanError(url: string, local = false) {
    store.dispatch(ajaxStart());
    Logger.debug(`GET [${url}]`);
    return new this(
      fetch((local ? this.localUrl : this.apiUrl) + url, {
        credentials: "include",
      }),
      true,
    );
  }

  static getForLogout(url: string, options: any) {
    store.dispatch(ajaxStart());
    return fetch(this.apiUrl + url, options)
      .then(() => {
        store.dispatch(ajaxStop());
      })
      .then((data) => data)
      .catch((error) => {
        throw error;
      });
  }

  static delete(url: string) {
    store.dispatch(ajaxStart());
    Logger.debug(`DELETE [${url}]`);
    return new this(
      fetch(this.apiUrl + url, { method: "DELETE", credentials: "include" }),
    );
  }

  static post(url: string, payload?: any) {
    store.dispatch(ajaxStart());
    Logger.debug(`POST [${url}] payload:`, payload);
    return new this(
      fetch(this.apiUrl + url, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(payload),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
    );
  }

  static put(url: string, payload: any) {
    store.dispatch(ajaxStart());
    Logger.debug(`PUT [${url}] payload:`, payload);
    return new this(
      fetch(this.apiUrl + url, {
        method: "PUT",
        credentials: "include",
        body: JSON.stringify(payload),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
    );
  }

  static postForm(url: string, payload: any) {
    store.dispatch(ajaxStart());
    Logger.debug(`POST [${url}] payload:`, payload);
    return new this(
      fetch(this.apiUrl + url, {
        credentials: "include",
        method: "POST",
        body: qs.stringify(payload),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded; charset=utf-8",
        },
      }),
    );
  }

  static sendFile(url: string, file: any) {
    let formData = new FormData();
    formData.append("file", file);
    store.dispatch(ajaxStart());
    Logger.debug(`POST [${url}] payload: file`);
    return new this(
      fetch(this.apiUrl + url, {
        credentials: "include",
        method: "POST",
        body: formData,
      }),
    );
  }

  then(callback: any, errHandler: any) {
    this.p
      .then((response: any) => {
        store.dispatch(ajaxStop());
        if (!response.ok) {
          let error: any = Error(response.status);
          error.status = response.status;
          error.response = response;
          throw error;
        }
        Logger.debug("Fetch OK:", response.url);
        return response;
      })
      .then((response: any) => response.json())
      .then((json: any) => {
        Logger.debug("Fetch OK:", json);
        json.meta.messages.forEach((m: any) => {
          //store.dispatch(pushToast(m.type.substring(0, 1).toLowerCase(), T('Server info: ') + m.text));
        });
        return callback(json);
      })
      .catch((error: any) => {
        if (error.status === 403) {
          store.dispatch(
            setValidation([
              { field: "username", msg: T("Wrong login or password") },
            ]),
          );
          error.message = "Błędny login lub hasło!";
          store.dispatch(clearUser());
          return;
        }

        if (error.status === 401) {
          store.dispatch(clearUser());
        }
        if (!this.ignoreError) {
          if (error.status === 404) {
            // store.dispatch(pushToast('e', T('Error: 404 - not found')));
          }

          if (error.status === 500) {
            error.response.json().then((r: any) => {
              Logger.debug(r);
              store.dispatch(setUnavailable());
            });
          }
        }

        errHandler && errHandler(error.message);

        Logger.error("Fetch error:", error);
      });
  }
}
