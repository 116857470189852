export default class Logger {

    static LEVEL = {
        DEBUG: 4,
        INFO: 3,
        WARNING: 2,
        ERROR: 1
    };

    static debug(...msg: any) {
        if (this.LEVEL.DEBUG <= ((window as any).var.LOG_LEVEL || 1))
            console.debug(...msg)
    }

    static info(...msg: any) {
        if (this.LEVEL.INFO <= ((window as any).var.LOG_LEVEL || 1))
            Logger.debug(...msg)
    }

    static warning(...msg: any) {
        if (this.LEVEL.WARNING <= ((window as any).var.LOG_LEVEL || 1))
            console.warn(...msg)
    }

    static error(...msg: any) {
        if (this.LEVEL.ERROR <= ((window as any).var.LOG_LEVEL || 1))
            console.error(...msg)
    }

}
