import React, {Component} from 'react';
import 'assets/css/table.css'
import {connect} from "react-redux";
import {mapStorageDispatchToProps, mapStorageToProps} from "app/redux/mappers";
import {FormGroup, Input} from "reactstrap";


class DtFilterSimple extends Component<any, any> {

    constructor(props: any) {
        super(props);
        this.state = {
            value: this.getValue()
        };
    }

    dataTableParams = () => {
        return this.props.dataTable[this.props.id + "_params"]
    };

    changeValue(e: any) {
        let newParams = this.dataTableParams();
        newParams.filters[this.props.filter.field + this.props.filter.operation] = {
            value: e.target.value,
            field: this.props.filter.field,
            operation: this.props.filter.operation,
            type: this.props.filter.type
        };
        this.props.setDataTableParams(newParams, this.props.id);
        this.setState({value: e.target.value})
    }

    getValue() {
        if (this.dataTableParams().filters[this.props.filter.field + this.props.filter.operation]) {
            return this.dataTableParams().filters[this.props.filter.field + this.props.filter.operation].value
        } else {
            return '';
        }
    }

    componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {
        if (this.getValue() !== this.state.value) {
            this.setState({value: ''})
        }
    }

    render() {
        return <div style={{marginLeft: '10px'}}><p
            style={{marginBottom: '0px', fontSize: '0.875em'}}>{this.props.filter.label}</p>
            <FormGroup>
                <Input value={this.state.value}
                       className="w-100 h-25 txt-input form-control login-box mt-1"
                       onChange={this.changeValue.bind(this)}
                       type={this.props.filter.type}/>
            </FormGroup>
        </div>
    }
}

export default connect(mapStorageToProps, mapStorageDispatchToProps)(DtFilterSimple);
