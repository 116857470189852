import T from "app/i18n/T";
import * as PropTypes from "prop-types";
import React from "react";
import {FormFeedback, Input, InputGroup} from "reactstrap";
import {getMsg, isValid} from "app/app-components/common/Validation";

export const MailRegister = (props: any) => <div id="multiCollapseExample3" className="top-border">
    <div className="d-flex align-items-start mt-3">
        <InputGroup>
            <Input type="text"
                   onChange={props.onChange}
                   className="w-100 txt-input form-control login-box mt-0 mb-2"
                   placeholder={T("E-mail address")} invalid={isValid("email")}/>
            <FormFeedback>{getMsg("email")}</FormFeedback>
        </InputGroup>
    </div>
</div>;

MailRegister.propTypes = {onChange: PropTypes.func};
