import React, { useEffect } from "react";
import { CustApi } from "app/api/CustApi";
import { connect } from "react-redux";
import "assets/css/dashboard-messages-badge.css";
import {
  mapStorageDispatchToProps,
  mapStorageToProps,
} from "app/redux/mappers";
import CustomerInfo from "app/app-components/dashboard/CustomerInfo";
import Contact from "app/app-components/dashboard/Contact";
import CustomerMsg from "app/app-components/dashboard/CustomerMsg";
import BuyService from "app/app-components/dashboard/BuyService";
import Payments from "app/app-components/dashboard/Payments";
import Services from "app/app-components/dashboard/Services";
import { OperApi } from "../../api/OperApi";
import Logger from "../../Logger";
import { ApiResponse } from "../../models/ApiModels";
import {
  IConsentsResponseDto,
  ICustomerInfoResponseDto,
  ILastSettlementResponseDto,
  IOperatorInfoResponseDto,
} from "@comfortel/ebok-model";

interface ViewDashboardProps {
  setCustomerInfo: (data: ICustomerInfoResponseDto) => void;
  setCustomerConsents: (data: IConsentsResponseDto) => void;
  setOperatorInfo: (data: IOperatorInfoResponseDto) => void;
  setCustomerPayment: (data: ILastSettlementResponseDto) => void;
}

const ViewDashboard = (props: ViewDashboardProps) => {
  const {
    setCustomerInfo,
    setCustomerConsents,
    setOperatorInfo,
    setCustomerPayment,
  } = props;

  useEffect(() => {
    CustApi.customerInfo().then(
      (r: ApiResponse<ICustomerInfoResponseDto>) => {
        if (r.data) {
          setCustomerInfo(r.data);
        }
        Logger.debug(r.data);
      },
      (error: any) => console.error(error),
    );

    CustApi.customerConsents().then(
      (r: ApiResponse<IConsentsResponseDto>) => {
        if (r.data) {
          setCustomerConsents(r.data);
        }
      },
      (error: any) => console.error(error),
    );

    OperApi.getOperatorContact().then(
      (r: ApiResponse<IOperatorInfoResponseDto>) => {
        if (r.data) {
          setOperatorInfo(r.data);
        }
      },
      (error: any) => console.error(error),
    );

    CustApi.customerLastSettlement().then(
      (r: ApiResponse<ILastSettlementResponseDto>) => {
        if (r.data) {
          setCustomerPayment(r.data);
        }
      },
      (error: any) => console.error(error),
    );
  }, [
    setCustomerInfo,
    setCustomerConsents,
    setOperatorInfo,
    setCustomerPayment,
  ]);

  return (
    <div className="container bgr-grey pt-3 pb-3">
      <div className="row">
        <div className="col col-md-6 col-lg-3 d-none d-md-block">
          <div className="row h-100">
            <div className="col">
              <CustomerInfo />
            </div>
          </div>
        </div>

        <div className="col col-12 col-md-6 col-lg-9">
          <div className="row">
            <div className="col col-12 col-xl-8 h-100">
              <Payments />
            </div>

            <div className="col col-12 col-xl-4 mt-4 mt-xl-0 h-xl-100">
              <Contact />
            </div>
          </div>
          <div className="row">
            <div className="col col-12 col-xl-12 mt-4 order-3 order-xl-1">
              <Services />
            </div>
          </div>
          <div className="row">
            <div className="col box02 col-12 col-xl-8 col-lg-6 col-xl-4 mt-4 order-xl-2">
              <CustomerMsg />
            </div>

            <div className="col col-12 col-lg-6 col-xl-4 mt-4 order-xl-3">
              <BuyService />
            </div>
          </div>
          {/*Ukrycie bannerów - brak w IWKD*/}
          {/*<div className="row mt-4 d-none d-lg-block">*/}
          {/*    <Banner/>*/}
          {/*</div>*/}
        </div>
      </div>

      {/*<div className="row mt-4 d-block d-lg-none">*/}
      {/*    <Banner/>*/}
      {/*</div>*/}
    </div>
  );
};

export default connect(
  mapStorageToProps,
  mapStorageDispatchToProps,
)(ViewDashboard);
